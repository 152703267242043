<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-0 header" fxLayoutGap="15px">
    <h1 class="main-heading-text my-0">{{title}}</h1>
    <div fxLayout="row">
        <button (click)="stopTour()" matTooltip="Chiudi tuor" mat-icon-button color="primary" class="bottomButton">
            <mat-icon class="my-icon">close</mat-icon>
        </button>
    </div>
</div>

<div fxLayout="row" ngClass.lt-md="px-8" ngClass.gt-sm="px-16" class="py-4 my-text">
    <p class="py-0 my-0" [innerHTML]="content"></p>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="py-8 px-4" fxLayoutGap="20px">
    <div>
        <button mat-flat-button class="my-button" *ngIf="hasPrev()" (click)="prev()">INDIETRO</button>
    </div>
    <div>
        <button [disabled]="isPending()" mat-flat-button color="primary" class="my-button" *ngIf="hasNext()" (click)="next()">AVANTI</button>
        <button [disabled]="isPending()" mat-flat-button color="primary" class="my-button" *ngIf="!hasNext()" (click)="stopTour()">CHIUDI TOUR</button>
    </div>
</div>