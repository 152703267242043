import {NgModule} from '@angular/core';
import LayerComponent from './layer/layer.component';
import {CommonModule} from '@angular/common';
import {EventListenerService} from './services/event-listener.service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [LayerComponent],
    imports: [CommonModule, FlexLayoutModule, MatIconModule, MatTooltipModule, MatButtonModule],
    providers: [EventListenerService],
    exports: []
})
export class DemoWizardModule {
}
