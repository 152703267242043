import {Component, Inject, Input, Optional, TemplateRef, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {fuseAnimations} from '../../animations';

@Component({
    selector   : 'fuse-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls  : ['./info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseInfoDialogComponent
{
    message: string = null;
    titleLabel = 'Informazioni';
    okLabel = 'Conferma';
    template: TemplateRef<any> = null;
    templateActions: TemplateRef<any> = null;

    /**
     * Constructor
     *
     * @param data
     * @param {MatDialogRef<FuseInfoDialogComponent>} dialogRef
     */
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FuseInfoDialogComponent>
    )
    {
        if (data) {
            if (data.message) {
                this.message = data.message;
            }
            if (data.titleLabel) {
                this.titleLabel = data.titleLabel;
            }
            if (data.okLabel) {
                this.okLabel = data.okLabel;
            }
            if (data.template) {
                this.template = data.template;
            }
            if (data.templateActions) {
                this.templateActions = data.templateActions;
            }
        }
    }

}
