import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickButtonDirective } from './click-button.directive';



@NgModule({
    exports: [
        ClickButtonDirective
    ],
    declarations: [
        ClickButtonDirective
    ]
})
export class DirectivesModule { }
