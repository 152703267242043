import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule, XhrFactory} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {AuthInterceptorService} from './main/services/auth/auth-interceptor.service';
import {ToastrModule} from 'ngx-toastr';
import {GoogleMapsAPIWrapper} from '@agm/core';
import {AuthenticationModule} from './main/authentication/authentication.module';
import {AuthGuard} from './main/services/auth/auth.guard';
import {PermGuard} from './main/services/auth/perm.guard';
import {ShareButtonModule} from 'ngx-sharebuttons/button';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {InMemoryBackendConfig, InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {FakeDbService} from './fake-db/fake-db.service';
import {InMemoryDbService} from 'angular-in-memory-web-api/interfaces';
import {httpClientInMemBackendServiceFactory} from './fake-db/demo/http-client-backend-custom.service';
import {DemoWizardModule} from './main/apps/demo-wizard/demo-wizard.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
        canLoad: [AuthGuard],
        canActivate : [PermGuard]
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
        canActivate : [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'pages/dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            apiBase: '',
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        // AppStoreModule,

        ToastrModule.forRoot(),
        ShareButtonModule.withConfig({
            debug: true
        }),
        ShareIconsModule,
        DemoWizardModule,
        ServiceWorkerModule.register('sw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        GoogleMapsAPIWrapper,

        // DEMO WIZARD
        {provide: InMemoryDbService, useClass: FakeDbService},
        {
            provide: InMemoryBackendConfig, useValue: {
                apiBase: '',
                delay: 0,
                passThruUnknownUrl: true
            }
        },
        {
            provide: HttpBackend,
            useFactory: httpClientInMemBackendServiceFactory,
            deps: [InMemoryDbService, InMemoryBackendConfig, XhrFactory]
        }
        // END DEMO WIZARD
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
