import {NgModule} from '@angular/core';
import {BecomeAmbassadorComponent} from './become-ambassador.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {FaqBaseModule} from '../../pages/faq/faq-base.module';

@NgModule({
    imports: [
        FuseSharedModule,
        FaqBaseModule
    ],
    exports: [
        BecomeAmbassadorComponent
    ],
    declarations: [BecomeAmbassadorComponent]
})
export class BecomeAmbassadorModule {}
