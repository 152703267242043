import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-avatar-showing',
    template: `
        <div aria-hidden="true" [style]="{'width': width, 'height': height}" *ngIf="_avatar !== null">
            <img #image [style]="{'width': width, 'height': height}" alt="avataar"/>
        </div>
    `,
    styleUrls: ['./avatar-showing.component.scss']
})
export class AvatarShowingComponent {

    private originalValue: string | any = null;
    _avatar: Blob = null;
    _avatarBase64: any = null;

    @ViewChild('image')
    private imageElement: ElementRef;

    @Input()
    width = '96px';

    @Input()
    height = '96px';

    @Input()
    set avatar(value: string | any) {
        if (value === this.originalValue) {
            return;
        }

        this.originalValue = value?.changingThisBreaksApplicationSecurity || value;
        this._avatar = new Blob([this.originalValue], {type: 'image/svg+xml'});

        const reader = new FileReader();
        reader.readAsDataURL(this._avatar);
        reader.onloadend = () => {

            this._avatarBase64 = reader.result;
            this.imageElement.nativeElement.src = this._avatarBase64;

        };

    }

    get avatar(): string | any {
        return this.originalValue;
    }

}
