import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';

import { MailConfirmComponent } from 'app/main/authentication/mail-confirm/mail-confirm.component';
import {DirectivesModule} from "../../apps/directives/directives.module";

const routes = [
    {
        path     : 'auth/mail-confirm',
        component: MailConfirmComponent
    },
    {
        path     : 'auth/mail-confirm/:email',
        component: MailConfirmComponent
    }
];

@NgModule({
    declarations: [
        MailConfirmComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatIconModule,

        FuseSharedModule,
        DirectivesModule
    ]
})
export class MailConfirmModule
{
}
