export enum UserStatusEnum {

     'DA_ATTIVARE' = 'DA_ATTIVARE',
     'PROFILO_DA_COMPLETARE' = 'PROFILO_DA_COMPLETARE',
     'PROFILO_COMPLETATO' = 'PROFILO_COMPLETATO',
     'FIRMA_IN_CORSO' = 'FIRMA_IN_CORSO',
     'IN_CORSO_DI_AUTORIZZAZIONE' = 'IN_CORSO_DI_AUTORIZZAZIONE',
     'ATTIVO' = 'ATTIVO',
     'BLOCCATO' = 'BLOCCATO',
     'CANCELLATO' = 'CANCELLATO',
     'VARIAZIONE_CONTRATTO' = 'VARIAZIONE_CONTRATTO',

    
}
