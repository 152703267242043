<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="h1 mb-12 red-500-fg">CONFERMA IN CORSO...</div>

            <div class="logo">
                <mat-icon class="mat-accent s-96">email</mat-icon>
            </div>

            <div class="subtitle" fxLayoutAlign="center center" fxLayout="column">
                <p>
                   Conferma email in corso attendi...
                </p>
                <mat-progress-spinner mode="indeterminate" *ngIf="loading"></mat-progress-spinner>

                <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!loading && !responseOK">
                    <mat-icon class="red-500-fg s-52">error</mat-icon>
                    <p>Conferma email fallita!</p>
                    <p>Probabilmente la tua email è già stata confermata. Se non ricordi la password
                        <a routerLink="/auth/forgot-password" class="red-500-fg font-weight-900">clicca qui</a> per eseguire il reset</p>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!loading && responseOK">
                    <mat-icon class="green-500-fg s-52">check</mat-icon>
                    <p>Conferma avvenuta con successo!</p>
                    <p>Tra qualche secondo verrai rediretto alla login page.</p>
                </div>
            </div>

        </div>

    </div>

    <div class="onda">
    </div>

</div>
