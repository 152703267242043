import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ConfirmationEmailComponent} from './confirmation-email.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'auth/email-confirmation',
        component: ConfirmationEmailComponent
    }
];

@NgModule({
    declarations: [ConfirmationEmailComponent],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatProgressSpinnerModule
    ]
})
export class ConfirmationEmailModule {
}
