import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Utilities} from './utilities.model';

declare const require: any;
const moment: any = require('moment');
moment.locale('it');

export class DateUtilities {
    static onlyDate: string = 'L'; // es per l'italia DD/MM/AAAA
    static onlyTime: string = 'LTS'; // es per l'italia hh:mm:ss
    static dateAndTime: string = 'L LTS'; // es per l'italia hh:mm:ss

    static today: Date = new Date(new Date().setHours(0, 0, 0, 0));
    static todayMorning: Date = new Date(new Date().setHours(7, 0, 0));
    /*static todayAfternoon: Date = new Date(new Date().setHours(12, 0, 0, 0));
    static todayEvening: Date = new Date(new Date().setHours(16, 0, 0, 0));
    static todayEveningMax: Date = new Date(new Date().setHours(18, 59, 59, 0));
    static olderDate: string = '01/01/1990 00:00:00';*/
    /*static olderUtcDate: string = DateUtilities.getUTCStringFromDate(new Date(1900, 1, 1, 0, 0, 0));
    static newerUtcDate: string = DateUtilities.getUTCStringFromDate(new Date(2099, 12, 31, 23, 59, 59));*/

    /*private static IT_MONTH_NAMES: { [key: string]: string[] } = {
        'long': 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
        'short': 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
        'narrow': 'G_F_M_A_M_G_L_A_S_O_N_D'.split('_')
    };

    private static IT_DAY_OF_WEEK_NAMES: { [key: string]: string[] } = {
        'long': 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split('_'),
        'short': 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
        'narrow': 'D_L_M_M_G_V_S'.split('_')
    };

    private static IT_FIRST_DAY_OF_WEEK: number = 1;*/

    static getDateNow(): string {
        const date_now: Date = new Date();
        return moment(date_now).format('L') + ' ' + moment(date_now).format('LTS');
    }

    static getNow(): Date {
        return new Date();
    }

    static isMoment(value: any): boolean {
        return moment.isMoment(value);
    }

    static getDateWithoutTimes(date: string): string {
        if (!Utilities.isVoid(date)) {
            if (date.indexOf(' ') > 0) {
                return date.substring(0, date.indexOf(' '));
            } else {
                return date;
            }
        } else {
            return '';
        }
    }

    static getDateUTCFromString(d: any): Date {
        if (!Utilities.isVoid(d)) {
            return new Date(moment(d).utc());
        }
        return null;
    }

    static getDate(d: any): Date {
        if (!Utilities.isVoid(d)) {
            return new Date(moment(d));
        }
        return null;
    }

    static getMomentUTC(d: any): any {
        return moment(d).utc();
    }

    static getMoment(d: any): any {
        return moment(d);
    }

    static getIsoStringFromDate(d: any): Date {
        if (!Utilities.isVoid(d)) {
            return moment.utc(d).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        }
        return null;
    }

    static getStringDateFromDate(d: any, pattern: string = 'YYYY-MM-DD'): string {
        if (!Utilities.isVoid(d)) {
            return moment(d).format(pattern);
        }
        return null;
    }

    static getStringDateAndHourFromDate(d: any): string {
        if (!Utilities.isVoid(d)) {
            return moment(d).format('DD/MM/YYYY HH:mm');
        }
        return null;
    }

    static getDayFromUTC(d: any): string {
        if (!Utilities.isVoid(d)) {
            return moment(d).format('DD/MM/YYYY');
        }
        return null;
    }

    static getUTCStringFromDate(d: any): string | null {
        if (!Utilities.isVoid(d) && moment(d).isValid()) {
            return moment.utc(d).format('YYYY-MM-DDTHH:mm:ssZZ');
        }
        return null;
    }

    static getUTCStringFromDateWithForm(d: any, format: any): string | null {
        if (!Utilities.isVoid(d) && !Utilities.isVoid(format) && moment(d, format, true).isValid()) {
            return moment.utc(d, format, true).format('YYYY-MM-DDTHH:mm:ssZZ');
        }
        return null;
    }

    static compareDateIsAfter(d1: Date | string, d2: Date | string): boolean {
        if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
            if (typeof d1 === 'string') {
                d1 = moment(d1, 'L, LTS');
            }
            if (typeof d2 === 'string') {
                d2 = moment(d2, 'L, LTS');
            }
            return moment(d1).isAfter(d2);
        }
        return false;
    }

    static compareDateIsBefore(d1: Date, d2: Date): boolean {
        if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
            return moment(d1).isBefore(d2);
        }
        return false;
    }

    static compareDateIsSame(d1: Date, d2: Date): boolean {
        if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
            return moment(d1).isSame(d2);
        }
        return false;
    }

    static isValid(d: any): boolean {
        if (!Utilities.isVoid(d)) {
            return moment(d).isValid();
        }
    }

    static getDateFromString(d: string, mFormat?: string): Date | null {
        let date: Date = null;
        if (!Utilities.isVoid(d) && d.length > 0) {
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            date = new Date(moment(DateUtilities.getDateWithoutTimes(d), mFormat));
            if (moment(date, mFormat, true).isValid()) {
                return date;
            }
        }
        return date;
    }

    static convertUtcDate(date: string, mFormat?: string): string {
        if (!Utilities.isVoid(date)) {
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            if (moment(date).isValid()) {
                return moment(date).utc().format(mFormat);
            }
        }
        return '';
    }

    static convertDate(date: string, mFormat?: string): string {
        if (!Utilities.isVoid(date)) {
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            if (moment(date).isValid()) {
                return moment(date).format(mFormat);
            }
        }
        return '';
    }

    static convertDateFromCustomFormat(date: string, customFormat: string, mFormat?: string): string {
        if (!Utilities.isVoid(date)) {
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            if (moment(date, customFormat).isValid()) {
                return moment(date, customFormat).format(mFormat);
            }
        }
        return '';
    }

    static getStringFromDate(date: any, mFormat?: string): string {
        if (!Utilities.isVoid(date)) {
            const d: Date = DateUtilities.getDate(date);
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            const res: any = moment(d.setHours(0, 0, 0, 0)).format(mFormat);
            if (moment(res, mFormat).isValid()) {
                return res;
            }
        }
        return null;
    }

    static getStringFromMoment(date: any, mFormat?: string): string {
        if (!Utilities.isVoid(date)) {
            if (Utilities.isVoid(mFormat)) {
                mFormat = 'L LTS';
            }
            const res: any = moment(date).format(mFormat);
            if (moment(res, mFormat).isValid()) {
                return res;
            }
        }
        return null;
    }

    static mergeTimeInDateForm(date: FormControl, time: FormControl): string {
        return moment(date.value).set({
            'hour': moment(time.value).get('hour'),
            'minute': moment(time.value).get('minute')
        }).format();
    }

    static getDiffBetweenTwoDates(d1: any, d2: any): number {
        const date: any = moment(d1);
        return date.diff(d2, 'days');
    }

    static setMomentLocale(): void {
        moment.locale('it');
        moment.updateLocale('it', {
            relativeTime: {
                future: '%s',
                s: function(number: any, withoutSuffix: any, key: any, isFuture: any) {
                    return 'alcuni secondi';
                },
            }
        });
    }

    static getTimeFromUtcDate(date: any): { h: string, m: string, s: string } {
        if (!!date) {
            const pipe: DatePipe = new DatePipe(moment.locale());

            const parsedDate: string = pipe.transform(date, 'HH:mm:ss');
            return {
                h: parsedDate.split(':')[0],
                m: parsedDate.split(':')[1],
                s: parsedDate.split(':')[2]
            };
        }
        return null;
    }

    /**
     * return end_date for calendar component
     * @param start_date
     */
    static getEndDateIntervalForCalendarFromStartDate(start_date: string): string {
        let end_date: string;
        const
            day: number = Number.parseInt(start_date.split('-')[2]),
            parsedMonth: number = Number.parseInt(start_date.split('-')[1]),
            parsedYear: number = Number.parseInt(start_date.split('-')[0]);

        switch (true) {
            case day === 1 && parsedMonth === 1:
            case day === 1 && parsedMonth === 3:
            case day === 1 && parsedMonth === 5:
            case day === 1 && parsedMonth === 7:
            case day === 1 && parsedMonth === 8:
            case day === 1 && parsedMonth === 10:
            case day === 1 && parsedMonth === 12:
                end_date = `${parsedYear}-${parsedMonth}-31`;
                break;
            case day === 1 && parsedMonth === 2:
                end_date = `${parsedYear}-${parsedMonth}-28`;
                break;
            case day === 1 && parsedMonth === 4:
            case day === 1 && parsedMonth === 6:
            case day === 1 && parsedMonth === 9:
            case day === 1 && parsedMonth === 11:
                end_date = `${parsedYear}-${parsedMonth}-30`;
                break;
            case day === 30 && parsedMonth === 1:
                end_date = `${parsedYear}-3-1`;
                break;
            case day === 31 && parsedMonth === 1:
                end_date = `${parsedYear}-3-2`;
                break;
            case day === 29 && parsedMonth === 2:
                end_date = `${parsedYear}-3-28`;
                break;
            default:
                end_date = `${parsedMonth !== 12 ? parsedYear : (parsedYear + 1)}-${parsedMonth !== 12 ? (parsedMonth + 1) : 1}-${day - 1}`;
                break;
        }
        return end_date;
    }

    static getDifferenceBetweenToDate(date_1: Date | string, date_2: Date | string): { days: number, hours: number, minutes: number, seconds: number } {

        let date_time1: number, date_time2: number;

        date_time1 = !date_1 ? new Date().getTime() : typeof date_1 === 'string' ? new Date(date_1).getTime() : date_1.getTime();
        date_time2 = !date_2 ? new Date().getTime() : typeof date_2 === 'string' ? new Date(date_2).getTime() : date_2.getTime();

        const
            cSeconds: number = 1000,
            cMinutes: number = cSeconds * 60,
            cHours: number = cMinutes * 60,
            cDays: number = cHours * 24;

        const
            diff: number = date_time1 - date_time2,

            d: number = Number.parseInt((diff / cDays).toString()),
            dDiff: number = (((diff / cDays) - d) * cDays),

            h: number = Number.parseInt((dDiff / cHours).toString()),
            hDiff: number = (((dDiff / cHours) - h) * cHours),

            m: number = Number.parseInt((hDiff / cMinutes).toString()),
            mDiff: number = (((hDiff / cMinutes) - m) * cMinutes),

            s: number = Number.parseInt((mDiff / cSeconds).toString());

        return {days: d, hours: h, minutes: m, seconds: s};
    }

    static addNumberToDate(date, number, type): Date {
        return moment(date).add(number, type).toDate();
    }

    static removeNumberToDate(date, number, type): Date {
        return moment(date).subtract(number, type).toDate();
    }

    static getLastDayOfMonth(mFormat: string): string {
        return moment().clone().endOf('month').format(mFormat);
    }

    static getFirstDayOfMonth(mFormat: string): string {
        return moment().clone().startOf('month').format(mFormat);
    }

    static getLastDayOfYear(mFormat: string): string {
        return moment().clone().endOf('year').format(mFormat);
    }

    static getFirstDayOfYear(mFormat: string): string {
        return moment().clone().startOf('year').format(mFormat);
    }

    static getNameOfMonth(monthNumber: number): string {
        return moment([2020, monthNumber, 1]).format('MMMM');
    }

}
