import {Component} from '@angular/core';
import {InfoDialogService} from '@fuse/components/info-dialog/info-dialog.service';
import {FusePerfectScrollbarService} from '../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.service';

@Component({
    selector: '[app-become-ambassador]',
    templateUrl: './become-ambassador.component.html',
    styleUrls: ['./become-ambassador.component.scss']
})
export class BecomeAmbassadorComponent {

    constructor(
        private fuseScrollBar: FusePerfectScrollbarService
    ) {
    }

    showUp(): void {
        this.fuseScrollBar.getByKey('fusePerfectScrollbarContainer3').scrollTo(0, 0, 500);
    }


}
