import {MatDateFormats} from '@angular/material/core';

export const APP_MOMENT_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: 'L'
	},
	display: {
		dateInput: 'L',
		monthYearLabel: 'Y',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'Y'
	}
};
