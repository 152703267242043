import {ComponentRef, ElementRef, Injectable, Injector, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {allDataStep, allStepDataMobile, DEMO_WIZARD_INJECTOR, ELEMENT_BODY, IStepInterface} from './step.interface';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import LayerComponent from '../layer/layer.component';
import {Router} from '@angular/router';
import {DrawOverlayService} from './draw-overlay.service';
import {EventListenerService} from './event-listener.service';
import {takeUntil} from 'rxjs/operators';
import {FusePerfectScrollbarService} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.service';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';

@Injectable({
    providedIn: 'root'
})
export class DemoWizardService {

    public tourFinish = false;
    private isPending = false;
    public isActive = false;
    private isDemoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isDemoSubject$: Observable<boolean> = this.isDemoSubject.asObservable();

    private demoWizardStepper: BehaviorSubject<IStepInterface> = new BehaviorSubject(null);
    private overlayRef: OverlayRef;

    private componentRef: ComponentRef<LayerComponent> = null;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    private _allStep: IStepInterface[] = [];

    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private router: Router,
        private renderFactory: RendererFactory2,
        private eventListener: EventListenerService,
        private sidebarService: FuseSidebarService,
        private fusePerfectScrollbarService: FusePerfectScrollbarService
    ) {
    }

    start(): void {
        this.demoWizardStepper.asObservable()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((result) => {
                if (result) {
                    this.router.navigate([result.page]).then(async () => {
                        if (result.handlerBeforeView) {
                            await result.handlerBeforeView({sidebarService: this.sidebarService});
                        }
                        this.checkElementExistsAndCreateOverlay(result);
                    });
                }
            });
        this.eventListener.startListeningResizeEvent();
        this.isDemoSubject.next(true);
        this.demoWizardStepper.next(this.getData()[0]);
        this.isActive = true;
    }

    setTourFinish(value: boolean): void {
        this.tourFinish = value;
    }

    stop(): void {
        this.isDemoSubject.next(false);
        this.demoWizardStepper.next(null);
        this.eventListener.stopListening();

        this.unsubscribeAll.complete();
        this.detachOverlay();
        this.setTourFinish(true);
        this.isActive = false;
        this._allStep = [];
        this.router.navigate(['pages/profile']);
    }

    private getOrigData(): IStepInterface[] {
        if (this._allStep.length === 0) {
            const dim = document.body.getBoundingClientRect().width;
            if (dim < 1280) {
                this._allStep = allStepDataMobile;
            } else {
                this._allStep = allStepDataMobile;
            }
        }
        return this._allStep;
    }

    private getData(): IStepInterface[] {
        return this.getOrigData();
    }

    hasNext(): boolean {
        const currentValue = this.demoWizardStepper.getValue();
        const data: IStepInterface[] = this.getData();
        return data.length > currentValue?.step;
    }

    hasPrevious(): boolean {
        const currentValue = this.demoWizardStepper.getValue();
        return currentValue?.step > 1;
    }

    getCurrent(): IStepInterface {
        return this.demoWizardStepper.getValue();
    }

    getIsPending(): boolean {
        return this.isPending;
    }

    nextDemoWizard(): void {
        if (this.hasNext()) {
            const currentValue = this.demoWizardStepper.getValue();
            const element = this.getData().find(it => it.step === currentValue?.step + 1);
            this.demoWizardStepper.next(element);
        }
    }

    prevDemoWizard(): void {
        if (this.hasPrevious()) {
            const currentValue = this.demoWizardStepper.getValue();
            const element = this.getData().find(it => it.step === currentValue.step - 1);
            this.demoWizardStepper.next(element);
        }
    }

    private getElementRef(id: string): ElementRef {
        const el: HTMLElement = document.getElementById(id);
        return new ElementRef<any>(el);
    }

    private checkElementExistsAndCreateOverlay(step: IStepInterface): boolean {
        const el: HTMLElement = document.getElementById(step.currentElement);
        if (el === null) {
            this.isPending = true;
            setTimeout(() => this.checkElementExistsAndCreateOverlay(step), 500);
            return;
        }

        this.isPending = false;
        this.createOverlay(this.getElementRef(step.currentElement), step);
    }

    private async createOverlay(el: ElementRef, step: IStepInterface): Promise<void> {
        const scrollStrategy = this.overlay.scrollStrategies.reposition();

        const positions = [step.originPosition];
        if (step.overlayPosition) {
            positions.push(step.overlayPosition);
        }
        const positionStrategy = this.overlay.position().flexibleConnectedTo(el)
            .withPositions(positions);

        if (this.componentRef) {
            this.detachOverlay();
        }

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: '',
        });

        const injector = Injector.create({
            providers: [{
                provide: DEMO_WIZARD_INJECTOR,
                useValue: {service: this, step}
            }]
        });
        const compoPortal: ComponentPortal<LayerComponent> = new ComponentPortal(LayerComponent, null, injector);
        this.componentRef = this.overlayRef.attach(compoPortal);
        const drawOverlay = new DrawOverlayService({
            el,
            backdropElement: this.overlayRef.backdropElement
        }, this.renderFactory);

        if (step.currentElement !== ELEMENT_BODY) {
            await this.scrollTo(0);
            await this.scrollTo(el.nativeElement.getBoundingClientRect().y - 200);
        }

        drawOverlay.draw(step);
        this.eventListener.resizeEvent.pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(() => drawOverlay.redraw());

        return;
    }

    private async scrollTo(y: number): Promise<void> {
        this.fusePerfectScrollbarService.getByKey('fusePerfectScrollbarContainer3')?.scrollTo(0, y, 100);
        return new Promise(resolve => setTimeout(() => resolve(), 300));
    }

    private detachOverlay(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef?.detach();
        }
        this.componentRef = null;
    }

}
