export let Qrcode: Blob = new Blob();
export const prepareBlob = async (url: string) => {

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((arg) => {
            Qrcode = arg;
            canvas = null;
        });
    };
    img.src = url;

    /*const response = await fetch(url, {mode: 'cors'});
    response.blob()
        .then(r => Qrcode = r)
        .catch(err => console.log(err))
    ;*/

};
prepareBlob('/assets/img/demo/QRCODE.jpg');
