import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from 'app/main/authentication/login/login.component';
import {BecomeAmbassadorModule} from '../become-ambassador/become-ambassador.module';
import {FaqBaseModule} from '../../pages/faq/faq-base.module';
import {MatDialogModule} from '@angular/material/dialog';
import {PolicyModule} from '../../apps/policy/policy.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path     : 'auth/login',
        component: LoginComponent
    }
];

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule,
        BecomeAmbassadorModule,
        FaqBaseModule,
        MatDialogModule,
        PolicyModule,
        MatProgressSpinnerModule
    ]
})
export class LoginModule
{
}
