import {NgModule} from '@angular/core';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import {FuseSharedModule} from "../../../../@fuse/shared.module";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [PrivacyPolicyComponent, TermsConditionComponent, CookiePolicyComponent],
    exports: [
        PrivacyPolicyComponent,
        TermsConditionComponent,
        CookiePolicyComponent
    ],
    imports: [
        FuseSharedModule,
        RouterModule
    ]
})
export class PolicyModule
{
}
