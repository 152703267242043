import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';

import {FuseSharedModule} from '@fuse/shared.module';

import {FaqBaseComponent} from 'app/main/pages/faq/faq-base.component';
import {FaqBaseArticleComponent} from 'app/main/pages/faq/dialogs/article/article.component';
import {FaqListComponent} from './faq-list.component';
import {TranslateModule} from '@ngx-translate/core';

const routes = [
    {
        path: 'knowledge-base',
        component: FaqBaseComponent
    }
];

@NgModule({
    declarations: [
        FaqBaseComponent,
        FaqBaseArticleComponent,
        FaqListComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatExpansionModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,

        FuseSharedModule,
        TranslateModule
    ],
    exports: [
        FaqListComponent
    ],
    entryComponents: [
        FaqBaseArticleComponent
    ]
})
export class FaqBaseModule {
}
