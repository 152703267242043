export const LandingDeployed = {
    'content': [{
        'id': 1,
        'folderName': 'promo-wau',
        'description': 'WAU',
        'subDescription': 'Preparati al Test medico-sanitario con WAU!  \r\nIl tuo sogno è diventare un professionista della salute? Preparati con WAU!: da anni aiutiamo tanti ragazzi a superare il test di medicina, veterinaria, professioni sanitarie e odontoiatria.',
        'startTime': '2021-10-08T14:00:00.622Z',
        'endTime': '2099-10-31T22:59:59Z',
        'type': 'AMBASSADOR',
        'url': 'https://offerte.salesmart.it/promo-wau',
        'previewImage': 'https://storage.googleapis.com/salesmartdp-storagetest/landing-anteprime/prepare.png',
        'detailPreviewImage': null,
        'leadOriginDigital': null,
        'leadOriginWeb': 'WAU_SALESMART'
    }],
    'pageable': {
        'sort': {'sorted': true, 'unsorted': false, 'empty': false},
        'pageNumber': 0,
        'pageSize': 1000,
        'offset': 0,
        'paged': true,
        'unpaged': false
    },
    'totalPages': 1,
    'last': true,
    'totalElements': 1,
    'sort': {'sorted': true, 'unsorted': false, 'empty': false},
    'number': 0,
    'numberOfElements': 1,
    'first': true,
    'size': 1000,
    'empty': false
};
