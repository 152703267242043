<div class="container-login" fxLayout="column">

    <div id="login" fxLayout="column">

        <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center top" fxFlex="100">

            <div fxLayout="column" fxLayout.gt-sm="row"
                 fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center stretch"
                 fxLayoutGap="15px">

                <div fxLayout="column" fxFlex.gt-md="1 1 700px">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start">
                        <div class="logo text-center" fxLayout="column" fxFlex="1 1 20%"
                             fxLayoutAlign="flex-start"
                             fxLayoutAlign.gt-xs="start center">
                            <img src="/assets/images/logos/logo.gif">
                        </div>
                        <div fxLayout="column" fxLayoutAlign="flex-start center" fxFlex="1 1 80%">
                            <h1 class="font-weight-600 orange-linkem-900-fg text-center mt-0 mb-0"
                                ngClass.gt-xs="font-size-40"
                                ngClass.gt-sm="mt-68" ngClass.xl="font-size-46" ngClass.lg="font-size-46"
                                ngClass.md="font-size-40" ngClass.xs="font-size-34"
                            >DIVENTA AMBASSADOR</h1>
                            <h2 class="font-weight-500 text-center" ngClass.lg="font-size-28"
                                ngClass.md="font-size-28" ngClass.xl="font-size-30" ngClass.sm="font-size-28"
                                ngClass.xs="font-size-20">
                                INIZIA A GUADAGNARE
                                <br>
                                PROMUOVENDO LE OFFERTE
                                <br>
                                DEI NOSTRI PARTNER
                            </h2>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button class="mb-20 border-radius-8" mat-raised-button color="accent" (click)="showDown()">SCOPRI DI
                                    PIÙ
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="login-info">
                        <div class="people-background" fxHide fxShow.gt-sm>
                            <img class="w-100-p" src="/assets/images/backgrounds/people-web.png" alt="">
                        </div>
                    </div>
                </div>

                <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" fxFlex.gt-sm="35"
                     fxFlex.gt-md="380px">

                    <div *ngIf="hasPartner()" fxLayout="row" fxLayoutAlign="center center" class="my-16">
                        <img [src]="partnerLogo()" fxFlex="100px"/>
                    </div>

                    <div class="login-register-click-wrapper" fxLayout.gt-sm="row" fxLayoutAlign="center center"
                         fxLayoutAlign.gt-sm="center stretch" fxLayoutGap="30px">
                        <div class="login-register-title font-weight-600"
                             (click)="show='login'"
                             [ngClass]="{activeL : show !== 'login', 'cursor-pointer': show !== 'login'}">
                            ACCEDI
                        </div>
                        <div class="login-register-title font-weight-600"
                             (click)="show='register'"
                             [ngClass]="{activeR : show !== 'register', 'cursor-pointer': show !== 'register'}">
                            REGISTRATI
                        </div>
                    </div>

                    <form name="loginForm" [formGroup]="loginForm" novalidate
                          *ngIf="show == 'login'">

                        <mat-form-field appearance="outline" class="mt-30">
                            <mat-label>E-Mail</mat-label>
                            <input matInput formControlName="email">
                            <mat-icon matPrefix class="secondary-text mr-8">mail</mat-icon>
                            <mat-error *ngIf="loginForm.get('email').hasError('required')">
                                E-mail obbligatoria
                            </mat-error>
                            <mat-error
                                *ngIf="!loginForm.get('email').hasError('required') &&
                                    loginForm.get('email').hasError('email')">
                                Inserisci una valida email
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password">
                            <mat-icon matPrefix class="secondary-text mr-8">vpn_key</mat-icon>
                            <mat-icon matSuffix class="secondary-text mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="!showPassword">
                                visibility
                            </mat-icon>
                            <mat-icon matSuffix class="eye-closed mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="showPassword">
                                visibility_off
                            </mat-icon>
                            <mat-error>
                                Password obbligatoria
                            </mat-error>
                        </mat-form-field>

                        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                             fxLayoutAlign="center center">

                            <a class="forgot-password" [routerLink]="'/auth/forgot-password'">
                                Hai dimenticato la tua password?
                            </a>
                        </div>

                        <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                                (click)="login()">
                            LOGIN
                        </button>

                    </form>

                    <form name="registerForm" class="form-register" [formGroup]="registerForm" novalidate
                          *ngIf="show=='register'">

                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name">
                            <mat-icon matPrefix class="secondary-text mr-8">account_circle</mat-icon>
                            <mat-error>
                                Nome obbligatorio
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Cognome</mat-label>
                            <input matInput formControlName="lastName">
                            <mat-icon matPrefix class="secondary-text mr-8">account_circle</mat-icon>
                            <mat-error>
                                Cognome obbligatorio
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>E-Mail</mat-label>
                            <input matInput formControlName="email">
                            <mat-icon matPrefix class="secondary-text mr-8">mail</mat-icon>
                            <mat-error *ngIf="registerForm.get('email').hasError('required')">
                                E-Mail obbligatoria
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('email').hasError('email')">
                                Inserire una mail valida
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>

                            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password">

                            <mat-icon matPrefix class="secondary-text mr-8">vpn_key</mat-icon>
                            <mat-icon matSuffix class="secondary-text mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="!showPassword">
                                visibility
                            </mat-icon>
                            <mat-icon matSuffix class="eye-closed mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="showPassword">
                                visibility_off
                            </mat-icon>
                            <mat-error>
                                Password obbligatoria
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Conferma Password</mat-label>

                            <input matInput [type]="showPassword ? 'text' : 'password'"
                                   formControlName="passwordConfirm">


                            <mat-icon matPrefix class="secondary-text mr-8">vpn_key</mat-icon>
                            <mat-icon matSuffix class="secondary-text mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="!showPassword">
                                visibility
                            </mat-icon>
                            <mat-icon matSuffix class="eye-closed mr-8 cursor-pointer"
                                      (click)="showPassword = !showPassword"
                                      *ngIf="showPassword">
                                visibility_off
                            </mat-icon>
                            <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                                Password di conferma obbligatoria
                            </mat-error>
                            <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                  registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                                Le password non coincidono
                            </mat-error>
                        </mat-form-field>

                        <div class="check" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <mat-checkbox color="accent" formControlName="terms"></mat-checkbox>
                            <p class="mb-0 mt-4 fuse-black-500-fg terms-conditions">
                                Dichiaro di aver letto e accettato le
                                <span class="cursor-pointer" (click)="openTerms()">
                              condizioni di utilizzo.
                          </span>
                            </p>
                        </div>

                        <mat-error class="font-size-10 check-error"
                                   *ngIf="registerForm.get('terms').invalid && registerForm.get('terms').touched">
                            Condizioni d'uso obbligatoria
                        </mat-error>

                        <div class="check" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <mat-checkbox formControlName="privacy-policy"></mat-checkbox>
                            <p class="mb-0 mt-4 fuse-black-500-fg terms-conditions">Dichiaro di aver letto e accettato
                                le
                                <span class="cursor-pointer" (click)="openPrivacy()">
                              privacy policy
                          </span>
                            </p>
                        </div>

                        <mat-error class="font-size-10 ml-10 check-error"
                                   *ngIf="registerForm.get('privacy-policy').invalid && registerForm.get('privacy-policy').touched">
                            Privacy policy obbligatoria
                        </mat-error>

                        <p *ngIf="alreadyExists" class="red-500-fg text-center">
                            E-Mail già esistente se non ricordi la password clicca
                            <a routerLink="/auth/forgot-password" class="blue-500-fg font-weight-900">qui</a>
                            per eseguire il recupero
                        </p>

                        <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                                *ngIf="!loading"
                                (click)="register()">
                            ISCRIVITI ORA
                        </button>

                        <div fxLayoutAlign="center center" *ngIf="loading">
                            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                        </div>

                    </form>

                </div>

            </div>

        </div>

        <div class="app-become-ambassador" app-become-ambassador></div>

        <div class="login-faq-list px-8"
             fxLayout="column" fxLayoutAlign="center center"
             ngClass.gt-md="px-24">
            <div class="w-90-p" app-faq-list [filters]="['AMBASSADOR_PUBLIC']"></div>
        </div>

        <footer id="footer" fxLayout="row" fxLayoutAlign="center center">
            <div class="card-footer logo-side text-center" fxLayout="column">
                <div class="img-logo-white">
                    <img src="/assets/images/logos/logo.gif">
                </div>
                <ul>
                    <li>
                        Viale Città d'Europa 681, <br>
                        00144, Roma
                    </li>
                    <li>
                        amministrazione@salesmart.it
                    </li>
                    <li>
                        <a href="https://www.facebook.com/salesmartsrl" target="_blank" title="Salesmart Facebook">
                            <mat-icon>facebook</mat-icon>
                        </a>
                    </li>
                </ul>

                <!-- <div class="card-footer-2 stamp-side font-size-10" fxLayout.gt-xs="row" fxLayout="column">
                    <span class="cursor-pointer grey-100-fg" (click)="openTerms()">
                        Terms and Conditions
                    </span> <span fxHide fxShow.gt-sm class="grey-100-fg"> &bull; </span>
                    <span class="cursor-pointer grey-100-fg" (click)="openPrivacy()">
                        Privacy Policy
                    </span> <span fxHide fxShow.gt-sm class="grey-100-fg">&bull;</span>
                    <span class="cursor-pointer grey-100-fg" (click)="openCookie()">
                        Cookie Policy
                    </span>
                </div> -->

            </div>
        </footer>
        <footer class="second-footer" fxLayout="row" fxLayoutAlign="center center">
            <span class="grey-100-fg font-size-10">
                © 2021 Salesmart S.r.l. |  All Rights Reserved
            </span>
            <span fxHide fxShow.gt-sm class="grey-100-fg">
                &bull;
            </span>
            <span class="cookie grey-100-fg font-size-10 cursor-pointer" (click)="openCookie()">
                Cookie Policy
            </span>
            <!-- <span class="cursor-pointer grey-100-fg" (click)="openTerms()">
                Terms and Conditions
            </span> <span fxHide fxShow.gt-sm class="grey-100-fg"> &bull; </span>
            <span class="cursor-pointer grey-100-fg" (click)="openPrivacy()">
                Privacy Policy
            </span> <span fxHide fxShow.gt-sm class="grey-100-fg">&bull;</span> -->
        </footer>


    </div>


</div>


<ng-template #showMore>
    <div app-become-ambassador></div>
</ng-template>

<ng-template #showMoreActions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="m-16">
        <button mat-raised-button color="primary" (click)="openFaq()">Domande Frequenti</button>
        <button mat-raised-button color="accent" mat-dialog-close>Continua</button>
    </div>
</ng-template>

<ng-template #faqList>
    <div app-faq-list [filters]="['AMBASSADOR_PUBLIC']"></div>
</ng-template>

<ng-template #invalidCredential>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="red-500-fg">warning</mat-icon>
        <p>Credenziali non valide!</p>
    </div>
</ng-template>

<ng-template #emailNotActive>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="red-500-fg">warning</mat-icon>
        <p>E-mail non verificata!</p>
        <p>Verifica e-mail prima di accedere.</p>
    </div>
</ng-template>

<ng-template #privacy>
    <div app-privacy-policy></div>
</ng-template>

<ng-template #termsRef>
    <div app-terms-condition></div>
</ng-template>

<ng-template #cookie>
    <div app-cookie-policy></div>
</ng-template>