import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
    selector: '[clickAsButton]'
})
export class ClickButtonDirective {

    @Input()
    link: string = null;

    constructor(
        private router: Router
    ) {
    }


    @HostListener('click')
    clickButton(): void {
        this.router.navigate([this.link]);
    }

}
