import {NgModule} from '@angular/core';
import {LoginModule} from './login/login.module';
import {ForgotPasswordModule} from './forgot-password/forgot-password.module';
import {ResetPasswordModule} from './reset-password/reset-password.module';
import {MailConfirmModule} from './mail-confirm/mail-confirm.module';
import {ConfirmationEmailModule} from './confirmation-email/confirmation-email.module';

@NgModule({
    imports: [
        // Authentication
        LoginModule,
        // Login2Module,
        // RegisterModule,
        // Register2Module,
        ForgotPasswordModule,
        // ForgotPassword2Module,
        ResetPasswordModule,
        // ResetPassword2Module,
        // LockModule,
        MailConfirmModule,
        ConfirmationEmailModule,
    ]
})
export class AuthenticationModule {
}
