export const Wallet = {
    'amount': 275.0,
    'totalLeadsAttivati': 0,
    'totalContracts': 5,
    'target': 0.0,
    'percentage': null,
    'paymentDate': null,
    'commissionings': [{
        'id': 9,
        'name': 'Offerte Consumer',
        'description': 'Offerte  Consumer',
        'collectedAmount': 275.0,
        'totalContracts': 5,
        'leadPaymentAmount': 5.0,
        'contractPaymentAmount': 55.0,
        'minimumAmountThreshold': 400.0,
        'minimumTimeThreshold': 30,
        'primaryColour': '#EE5340',
        'secondaryColour': '#F39C12',
        'months': [{
            'month': 6,
            'year': 2021,
            'totalEarning': 110.0,
            'totalContracts': 2,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 2
            }],
            'yearMonth': 'giu 2021'
        }, {
            'month': 7,
            'year': 2021,
            'totalEarning': 55.0,
            'totalContracts': 1,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 1
            }],
            'yearMonth': 'lug 2021'
        }, {
            'month': 8,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'ago 2021'
        }, {
            'month': 9,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'set 2021'
        }, {
            'month': 10,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'ott 2021'
        }, {
            'month': 11,
            'year': 2021,
            'totalEarning': 110.0,
            'totalContracts': 2,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2C',
                'totalLeadsAttivati': 0,
                'totalContracts': 2
            }],
            'yearMonth': 'nov 2021'
        }],
        'leadOrigins': null,
        'deleted': false,
        'defaultConfiguration': true
    }, {
        'id': 3,
        'name': 'Offerte Special',
        'description': 'JUST SPEED desc',
        'collectedAmount': 0.0,
        'totalContracts': 0,
        'leadPaymentAmount': 0.0,
        'contractPaymentAmount': 300.0,
        'minimumAmountThreshold': 0.0,
        'minimumTimeThreshold': 0,
        'primaryColour': '#27AE60',
        'secondaryColour': '#2ECC71',
        'months': [{
            'month': 6,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'giu 2021'
        }, {
            'month': 7,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'lug 2021'
        }, {
            'month': 8,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'ago 2021'
        }, {
            'month': 9,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'set 2021'
        }, {
            'month': 10,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'ott 2021'
        }, {
            'month': 11,
            'year': 2021,
            'totalEarning': 0.0,
            'totalContracts': 0,
            'totalLeads': null,
            'offers': [{
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_SPECIAL',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_B2B',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }, {
                'idLandingProperties': 0,
                'urlLanding': null,
                'previewLanding': null,
                'leadOrigin': 'LEAD_AMBASSADOR_TEST',
                'totalLeadsAttivati': 0,
                'totalContracts': 0
            }],
            'yearMonth': 'nov 2021'
        }],
        'leadOrigins': null,
        'deleted': false,
        'defaultConfiguration': null
    }]
};
