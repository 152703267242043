<div class="cookie-policy">
    <a class="m-0" name="top" href="/">&nbsp;</a>
    <h2 class="mt-0">Web Privacy & Cookie Policy</h2>

    <p>Salesmart S.r.l. con sede legale in Viale Città D’Europa, 681 – Roma - (di seguito “Salesmart ”) si impegna
        costantemente per tutelare la privacy on-line dei suoi utenti. Questo documento è stato redatto ai sensi
        dell’art.
        13 del Regolamento UE 2016/679 (di seguito: "Regolamento") al fine di permetterle di conoscere la nostra
        politica
        sulla privacy, per capire come le sue informazioni personali vengono gestite quando utilizza la piattaforma
        online
        (di seguito “Piattaforma”) e, se del caso, di prestare un consenso al trattamento dei Suoi dati personali
        espresso e
        consapevole. Le informazioni ed i dati da Lei forniti od altrimenti acquisiti nell'ambito dell’utilizzo dei
        servizi
        di Salesmart - come ad esempio: l’accesso all’area riservata della Piattaforma, di seguito “Servizi", saranno
        oggetto di trattamento nel rispetto delle disposizioni del Regolamento e degli obblighi di riservatezza che
        ispirano
        l'attività di Salesmart.
        Secondo le norme del Regolamento, i trattamenti effettuati da Salesmart saranno improntati ai principi di
        liceità,
        correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati, esattezza,
        integrità e riservatezza.</p>
    <p>INDICE</p>
    <ol>
        <li>Titolare del trattamento</li>
        <li>I dati personali oggetto di trattamento</li>
        <li>Dati di navigazione</li>
        <li>Speciali categorie di dati personali</li>
        <li>Dati forniti volontariamente dall’interessato</li>
        <li>Cookie</li>
        <li>Finalità del trattamento</li>
        <li>Base giuridica e natura obbligatoria o facoltativa del trattamento</li>
        <li>Destinatari dei dati personali</li>
        <li>Trasferimenti dei dati personali</li>
        <li>Conservazione dei dati personali</li>
        <li>Diritti dell’interessato</li>
    </ol>

    <h3>1. Titolare del trattamento e Responsabile per la Protezione Dei Dati Personali “DPO”</h3>
    <p>Il titolare dei trattamenti svolti attraverso la Piattaforma è Salesmart come sopra definito, raggiungibile
        all’indirizzo e-mail salesmartsrl@legalmail.it.</p>

    <h3>2. I dati personali oggetto del trattamento</h3>
    <p>A seguito della navigazione del Sito, La informiamo che Salesmart tratterà i Suoi dati personali che potranno
        essere
        costituiti da un identificativo come il nome, un numero di identificazione, un identificativo online o a uno o
        più
        elementi caratteristici della Sua identità fisica, economica, culturale o sociale idonea a rendere il soggetto
        interessato identificato o identificabile (di seguito solo “Dati Personali”).
        I Dati Personali trattati attraverso il Sito sono i seguenti:</p>

    <h3>a. Dati di navigazione</h3>
    <p>I sistemi informatici e le procedure software preposte al funzionamento della Piattaforma acquisiscono, nel corso
        del loro normale esercizio, alcuni Dati Personali la cui trasmissione è implicita nell'uso dei protocolli di
        comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati
        identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati
        detenuti da
        terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a
        dominio dei computer utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione URI (Uniform
        Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la
        richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della
        risposta data dal server (buon fine, errore, etc.) ed altri parametri relativi al sistema operativo e
        all'ambiente
        informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche
        anonime
        sull'uso della Piattaforma e per controllarne il corretto funzionamento, per identificare anomalie e/o abusi, e
        vengono cancellati immediatamente dopo l'elaborazione. I dati potrebbero essere utilizzati per l'accertamento di
        responsabilità in caso di ipotetici reati informatici ai danni della Piattaforma o di terzi: salva questa
        eventualità, allo stato i dati sui contatti web non persistono per più di sette giorni.</p>


    <h3>b. Dati forniti volontariamente dall’interessato</h3>
    <p>Nell'utilizzo di alcuni Servizi della Piattaforma potrebbe verificarsi un trattamento di Dati Personali di terzi
        soggetti da Lei inviati a Salesmart . Rispetto a tali ipotesi, Lei si pone come autonomo titolare del
        trattamento,
        assumendosi tutti gli obblighi e le responsabilità di legge. In tal senso, conferisce sul punto la più ampia
        manleva
        rispetto ad ogni contestazione, pretesa, richiesta di risarcimento del danno da trattamento, ecc. che dovesse
        pervenire a Salesmart da terzi soggetti i cui Dati Personali siano stati trattati attraverso il Suo utilizzo
        delle
        funzioni della Piattaforma in violazione delle norme sulla tutela dei dati personali applicabili. In ogni caso,
        qualora fornisse o in altro modo trattasse Dati Personali di terzi nell'utilizzo del Sito, garantisce fin da ora
        -
        assumendosene ogni connessa responsabilità - che tale particolare ipotesi di trattamento si fonda su un’idonea
        base
        giuridica ai sensi dell’art. 6 del Regolamento che legittima il trattamento delle informazioni in questione.</p>

    <h3>c. Cookie</h3>
    <h3>Cosa sono e a cosa servono?</h3>
    <p>I cookie sono piccoli file di testo che i siti visitati dall'utente inviano e registrano sul suo browser, per
        essere
        poi ritrasmessi agli stessi siti alla successiva visita.
        Proprio grazie ai cookie un sito ricorda le azioni e preferenze dell'utente (come, ad esempio, i dati di login,
        la
        lingua prescelta, le dimensioni dei caratteri, altre impostazioni di visualizzazione, ecc.) in modo che non
        debbano
        essere indicate nuovamente quando l'utente torni a visitare detto sito o navighi da una pagina all'altra di
        esso. I
        cookie, quindi, sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione
        di
        informazioni riguardanti le attività degli utenti che accedono ad un sito e possono contenere anche un codice
        identificativo unico che consente di tenere traccia della navigazione dell'utente all'interno del sito stesso
        per
        finalità statistiche o pubblicitarie. Nel corso della navigazione su un sito, l'utente può ricevere sul suo
        browser
        anche cookie di siti o di web server diversi da quello che sta visitando (c.d. cookie di "terze parti"). Alcune
        operazioni non potrebbero essere compiute senza l'uso dei cookie, che in certi casi sono quindi tecnicamente
        necessari per lo stesso funzionamento del sito.</p>

    <h3>Classificazione dei cookie:</h3>
    <p>Esistono vari tipi di cookie, a seconda delle loro caratteristiche e funzioni, e questi possono rimanere nel
        browser
        dell'utente per periodi di tempo diversi: c.d. cookie di sessione, che viene automaticamente cancellato alla
        chiusura del browser; c.d. cookie persistenti, che permangono sull'apparecchiatura dell'utente fino ad una
        scadenza
        prestabilita.</p>
    <p>
        In base alla normativa vigente in Italia, per l'utilizzo dei cookie non sempre è richiesto un espresso consenso
        dell'utente. In particolare, non richiedono tale consenso i "cookie tecnici", cioè quelli utilizzati al solo
        fine di
        effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura
        strettamente necessaria per erogare un servizio esplicitamente richiesto dall'utente. Si tratta, in altre
        parole, di
        cookie indispensabili per il funzionamento del sito o necessari per eseguire attività richieste dall'utente.
        Tra i cookie tecnici, che non richiedono un consenso espresso per il loro utilizzo, il Garante per la protezione
        dei
        dati personali italiano (cfr. Provvedimento Individuazione delle modalità semplificate per l'informativa e
        l'acquisizione del consenso per l'uso dei cookie dell'8 maggio 2014 e successivi chiarimenti, di seguito solo
        "Provvedimento") nonché le Linee Guida per l’utilizzo di cookie e di altri strumenti di tracciamento avviata
        dall’Autorità Garante con il provvedimento n. 255 del 26 novembre 2020, le Linee Guida dell’European Data
        Protection
        Board (EDPB) n. 5-2020 sul consenso adottate il 4 maggio 2020 ed ulteriori pronunce in ambito europeo quali le
        Raccomandazioni del 14 gennaio 2020 dell’Autorità garante francese e Linee guida Guida dell’ Agenzia spagnola
        per la
        protezione dei dati (AEPD) del luglio 2020 ricomprendono anche:
    </p>

    <ul>
        <li>"cookie analytics" laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in
            forma
            aggregata, sul numero degli utenti e su come questi visitano il sito stesso,
        </li>
        <li>cookie di navigazione o di sessione (per autenticarsi),</li>
        <li>cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri
            selezionati
            (ad esempio, la lingua, i prodotti selezionati per l'acquisto) al fine di migliorare il servizio reso allo
            stesso.
        </li>
    </ul>
    <p>Per i "cookie di profilazione", viceversa, cioè quelli volti a creare profili relativi all'utente e utilizzati al
        fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell'ambito della
        navigazione in rete, è richiesto un preventivo consenso dell'utente.</p>

    <h3>Tipologie di cookie utilizzati dalla Piattaforma e possibilità di selezione</h3>
    <p>Il Sito utilizza i seguenti cookie:</p>
    <ul>
        <li>Cookie tecnici di navigazione o sessione e strettamente necessari per il funzionamento della Piattaforma o
            per
            consentirle di usufruire dei contenuti e dei servizi richiesti.
        </li>
        <li>Cookie di funzionalità, cioè utilizzati per attivare specifiche funzionalità della Piattaforma e una serie
            di
            criteri selezionati (ad esempio, la lingua) al fine di migliorare il servizio reso.
        </li>
        <li>Cookie tecnici/analitici di performance, cioè utilizzati per migliorare il servizio reso
            (SC_ANALYTICS_SESSION_COOKIE / SC_ANALYTICS_GLOBAL_COOKIE).
        </li>
        <li>Cookie di terze parti, cioè cookie di siti o di web server diversi da quello di Salesmart, utilizzati per
            finalità
            proprie di dette parti terze. Si precisa che tali soggetti terzi, di seguito elencati con i relativi
            collegamenti
            alle politiche sulla privacy, sono tipicamente autonomi titolari del trattamento dei dati raccolti
            attraverso i
            cookie da essi serviti; pertanto, dovrà fare riferimento alle loro politiche di trattamento di dati
            personali,
            informative e moduli di consenso (selezione e de-selezione dei rispettivi cookie), come precisato nel citato
            Provvedimento. Per completezza inoltre si precisa che Salesmart fa il massimo per poter tracciare i cookie
            sul
            proprio Sito. Questi sono aggiornati in maniera regolare nella tabella che segue, dove diamo trasparenza sui
            cookie
            direttamente inviati da Salesmart e sulle loro finalità. Per quanto concerne le terze parti che inviano
            cookie
            tramite il nostro Sito, forniamo di seguito i link alle rispettive informative privacy: a tali terze parti
            demandiamo, come già precisato, la responsabilità di fornire l'informativa e raccogliere il Suo consenso,
            come
            previsto dal Provvedimento. Detta responsabilità è da riferirsi non solo ai cookie che le terze parti
            inviano
            direttamente, ma anche ad eventuali ulteriori cookie che vengono inviati tramite il nostro Sito in virtù
            dell'utilizzo di servizi di cui le terze parti stesse fruiscono. Rispetto a tali cookie infatti, inviati da
            fornitori di servizi delle citate terze parti, Salesmart non ha la possibilità di esercitare alcun controllo
            e non
            ne conosce né le caratteristiche né le finalità.
        </li>
    </ul>
    <p>Nella tabella riportiamo i link alle informazioni sui cookie di terze parti, con i relativi link alle informative
        privacy:</p>

    <h3>Cookie di terze parti presenti nella Piattaforma</h3>
    <p>Salesmart acquisisce il consenso all’utilizzo dei cookie sopraelencati</p>

    <div fxLayout="row" fxLayout.gt-xs="column">
        <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayout.gt-xs="row"
             fxLayoutAlign="center start"
             fxLayoutAlign.gt-xs="space-between center">
            <p fxFlex="33">Tipo di cookie</p>
            <p fxFlex="33">Nome tecnico dei cookies</p>
            <p fxFlex="33">Privacy policy</p>
        </div>
        <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayout.gt-xs="row"
             fxLayoutAlign="center start"
             fxLayoutAlign.gt-xs="space-between center"
        >
            <p fxFlex="33">Tecnici/Analitici</p>
            <p fxFlex="33">GOOGLE.COM</p>
            <p fxFlex="33" fxLayout="column">
                <a href="https://policies.google.com/privacy?hl=it&gl=it" target="_blank">(informativa privacy)</a>
                <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">(opt-in/opt-out consenso)</a>
            </p>
        </div>
    </div>

    <p>attraverso un banner presente al primo accesso alla Piattaforma Lei può :</p>
    <ul>
        <li>accettare tutti i cookie cliccando sul pulsante ACCETTA, oppure selezionare le tipologie;</li>
        <li>accedere alla presente pagina dell’informativa, raggiungibile mediante link collocato in calce ad ogni
            pagina
            della Piattaforma
        </li>
    </ul>

    <h3>Impostazioni relative ai cookie</h3>
    <p>Può bloccare o cancellare (in tutto o in parte) i cookie tecnici e di funzionalità attraverso le specifiche
        funzioni
        del suo Browser. Ti informiamo tuttavia che non autorizzare i cookie tecnici potrebbe comportare l'impossibilità
        di
        utilizzare il Sito, visionarne i contenuti ed usufruire dei relativi servizi. Inibire i cookie di funzionalità
        potrebbe comportare che alcuni servizi o determinate funzioni della Piattaforma non siano disponibili o non
        funzionino correttamente e potresti essere costretto a modificare o a inserire manualmente alcune informazioni o
        preferenze ogni volta che visiterà il Sito.</p>

    <p>Le scelte operate in riferimento ai cookie della Piattaforma saranno a loro volta registrate in un apposito
        cookie.
        Tale cookie potrebbe, tuttavia, in alcune circostanze non funzionare correttamente: in tali casi, Le consigliamo
        di
        cancellare i cookie non graditi e di inibirne l'utilizzo anche attraverso le funzionalità dei suoi Browser.</p>
    <p>
        Le sue preferenze in riferimento ai cookie andranno reimpostate nel caso in cui utilizzi diversi dispositivi o
        Browser per accedere al Sito.</p>
    <p>Come visualizzare e modificare i cookie attraverso il Browser Può autorizzare, bloccare o cancellare (in tutto o
        in
        parte) i cookie attraverso le specifiche funzioni del Suo Browser. Per avere maggiori informazioni su come
        impostare
        le preferenze sull'uso dei cookie attraverso il Browser, è possibile consultare le relative istruzioni:</p>

    <ul>
        <li>Internet Explorer</li>
        <li>Firefox</li>
        <li>Chrome</li>
        <li>Safari</li>
    </ul>

    <h3>3. Finalità del trattamento</h3>
    <p>Il trattamento che intendiamo effettuare, dietro Suo specifico consenso ove necessario, ha le seguenti
        finalità:</p>
    <ul>
        <li>Consentire l'erogazione dei Servizi da Lei richiesti, ossia: i) l’accesso all’area riservata del Sito ii)
            concludere un contratto;
        </li>
        <li>assolvere eventuali obblighi di legge, contabili e fiscali.</li>
    </ul>


    <h3>4. Base giuridica e natura obbligatoria o facoltativa del trattamento</h3>
    <p>La base giuridica del trattamento di Dati Personali per la finalità di cui alla sezione 3 a è l’art. 6.1.b) del
        Regolamento in quanto i trattamenti sono necessari all'erogazione dei Servizi. Il conferimento dei Dati
        Personali
        per questa finalità è facoltativo ma l'eventuale mancato conferimento comporterebbe l'impossibilità di attivare
        i
        Servizi forniti dalla Piattaforma.</p>
    <p>La finalità di cui alla sezione 3.b rappresenta un trattamento legittimo di Dati Personali ai sensi dell’art.
        6.1.c)
        del Regolamento.</p>

    <h3>5. Destinatari dei dati personali</h3>
    <p>I Suoi Dati Personali potranno essere condivisi, per le finalità di cui alla sezione 3 più sopra, con:
    a. soggetti che agiscono tipicamente in qualità di responsabili del trattamento ex art. 28 del Regolamento ossia: i)
    persone, società o studi professionali che prestano attività di assistenza e consulenza a Salesmart in materia
    contabile, amministrativa, legale, tributaria, finanziaria; ii) soggetti con i quali sia necessario interagire per
    l'erogazione dei Servizi (ad esempio gli hosting provider) iii) ovvero soggetti delegati a svolgere attività di
    manutenzione tecnica (inclusa la manutenzione degli apparati di rete e delle reti di comunicazione elettronica);
    (collettivamente “Destinatari”); l’elenco dei responsabili del trattamento che trattano dati può essere richiesto al
    Titolare scrivendo al seguente indirizzo: salesmartsrl@legalmail.it.;
    b. soggetti, enti od autorità, autonomi titolari del trattamento, a cui sia obbligatorio comunicare i Suoi Dati
    Personali in forza di disposizioni di legge o di ordini delle autorità;
    c. persone autorizzate da Salesmart al trattamento di Dati Personali ex art. 29 del Regolamento necessario a
    svolgere attività strettamente correlate all'erogazione dei Servizi, che si siano impegnate alla riservatezza o
        abbiano un adeguato obbligo legale di riservatezza (es. dipendenti di Salesmart).</p>

    <h3>6. Trasferimenti dei dati personali</h3>
    <p>I Suoi dati personali potranno essere trasferiti al di fuori dello Spazio Economico Europeo. Salesmart rende noto
    che il trattamento avverrà secondo una delle modalità consentite dalla legge vigente, quali ad esempio il consenso
    dell’interessato, l’adozione di Clausole Standard approvate dalla Commissione Europea, la selezione di soggetti
    aderenti a programmi internazionali per la libera circolazione dei dati od operanti in Paesi considerati sicuri
    dalla Commissione Europea con decisione di adeguatezza, nel rispetto delle raccomandazioni 2/2020 dell’European Data
        Protection Board. È possibile avere maggiori informazioni, su richiesta, presso il Titolare.</p>

    <h3>7. Conservazione dei dati</h3>
    <p>I Dati Personali trattati per la finalità di cui alla sezione 3(a) saranno conservati per il tempo strettamente
    necessario a raggiungere quella stessa finalità nel rispetto dei principi di minimizzazione e limitazione della
    conservazione ex artt. 5.1.e) del Regolamento. In ogni caso, Salesmart tratterà i Dati Personali per il tempo
        necessario ad adempimenti contrattuali e di legge.</p>

    <h3>8. Diritti degli interessati</h3>
    <p>Ai sensi degli articoli 15 e seguenti del Regolamento, hai il diritto di chiedere in qualunque momento, l'accesso ai
    tuoi Dati Personali, la rettifica o la cancellazione degli stessi, la limitazione del trattamento nei casi previsti
    dall'art. 18 del Regolamento, ottenere in un formato strutturato, di uso comune e leggibile da dispositivo
    automatico i dati che la riguardano, nei casi previsti dall'art. 20 del Regolamento.
    Puoi formulare una richiesta di opposizione al trattamento dei Suoi dati ex articolo 21 del GDPR nella quale dare
    evidenza delle ragioni che giustifichino l’opposizione: il Titolare si riserva di valutare l’istanza, che non
    verrebbe accettata in caso di esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgano sui
        tuoi interessi, diritti e libertà.</p>
    <p>Le richieste vanno rivolte per iscritto al Titolare all’ indirizzo della sede legale di Salesmart S.r.l. alla e-mail
        salesmartsrl@legalmail.it.</p>

    <h3>9. Reclamo al Garante</h3>
    <p>In ogni caso, qualora Lei ritenga che il trattamento dei dati personali effettuato dal Titolare, avvenga in
    violazione di quanto previsto dalla normativa in vigore, ha il diritto di proporre reclamo all'Autorità Garante per
    la Protezione dei Dati Personali, come previsto dall'art. 77 del Regolamento, o di adire le opportune sedi
        giudiziarie ai sensi dell’art. 79 del GDPR.</p>


</div>