<mat-toolbar>
    <div fxLayout="column-reverse" fxLayout.gt-xs="row" class="w-100-p"
         fxLayoutAlign="start start"
         fxLayoutAlign.gt-sm="space-between center" fxFlex>
        <div class="p w-100-p">Servizio gestito da: Salesmart S.r.l. P.IVA 15777811009 gruppo Tiscal Italia S.p.A.</div>
        <div fxLayout="column" fxLayout.gt-xs="row"
             fxLayoutAlign="start start" fxLayoutAlign.gt-sm="start center">
            <button mat-button (click)="openTerms()">Terms and Condition</button>
            <span fxHide fxShow.gt-sm>&bull;</span>
            <button mat-button (click)="openPrivacy()">Privacy Policy</button>
            <span fxHide fxShow.gt-sm>&bull;</span>
            <button mat-button (click)="openCookie()">Cookie Policy</button>
        </div>
    </div>
</mat-toolbar>

<ng-template #privacy>
    <div app-privacy-policy></div>
</ng-template>
<ng-template #cookie>
    <div app-cookie-policy></div>
</ng-template>
<ng-template #terms>
    <div app-terms-condition></div>
</ng-template>
