import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {PwdService} from '../../services/auth/pwd.service';
import {FuseConfigService} from '@fuse/services/config.service';

@Component({
    selector: 'app-confirmation-email',
    templateUrl: './confirmation-email.component.html',
    styleUrls: ['./confirmation-email.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ConfirmationEmailComponent implements OnInit {

    private token: string;
    responseOK = false;
    loading = true;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _activatedRoute: ActivatedRoute,
        private _pwdService: PwdService,
        private _router: Router
    ) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }

    ngOnInit(): void {
        this.token = this._activatedRoute.snapshot.queryParamMap.get('token');
        setTimeout(() => {
            this.confirm();
        }, 3000);
    }

    confirm(): void {
        this._pwdService.confirmationEmail(this.token).subscribe(resp => {
            this.loading = false;
            this.responseOK = true;
            setTimeout(() => {
                this.gotoLogin();
            }, 3000);
        }, () => {
            this.loading = false;
            this.responseOK = false;
        });
    }

    gotoLogin(): void {
        this._router.navigate(['/auth/login']);
    }

}
