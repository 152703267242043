import {Injectable} from '@angular/core';
import {MatSnackBarRef} from '@angular/material/snack-bar';
import {ComponentType} from '@angular/cdk/overlay';
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {

    private customComponent: Map<string, MatSnackBarRef<any>> = new Map<string, MatSnackBarRef<any>>();

    constructor(
        private toastr: ToastrService
    ) {
    }

    private _message(message: string): void {
        this.toastr.show(message, '', {
            timeOut: 3000
        });
    }

    addCustomComponent(componentType: ComponentType<any>): ActiveToast<any> {
        return this.toastr.show('', '', {
            toastComponent: componentType,
            positionClass: 'toast-bottom-right'
        });
    }

    removeCustomComponent(toastId: number): void {
        this.toastr.remove(toastId);
    }

    showError(): void {
        this.toastr.error('Ops qualcosa è andato storto!', 'Errore', {
            closeButton: true,
            timeOut: 3000
        });
    }

    showCustomError(message: string, duration: number = 6000): void {
        this.toastr.error(message, 'Errore', {
            closeButton: true,
            timeOut: duration
        });
    }

    showSuccess(): void {
        this.addSuccessMessage('Operazione eseguita con success!');
    }

    addMessage(message: string): void {
        this._message(message);
    }

    addWarningMessage(message: string, timeOut: number = 3000): void {
        this.toastr.warning(message, 'Attenzione', {
            timeOut: timeOut,
            closeButton: true
        });
    }

    addSuccessMessage(message: string, timeOut: number = 3000): void {
        this.toastr.success(message, 'Info', {
            timeOut: timeOut,
            closeButton: true
        });
    }

    addErrorMessage(message: string, timeOut: number = 3000): void {
        this.toastr.error(message, 'Errore', {
            timeOut: timeOut,
            closeButton: true
        });
    }

}
