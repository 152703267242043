<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="conferma h1 mb-12">CONFERMA IL TUO ACCOUNT</div>

            <div class="logo">
                <mat-icon class="mat-accent s-96">email</mat-icon>
            </div>

            <div class="subtitle">
                <p>
                   Ti abbiamo inviato una e-mail di conferma
                    <span *ngIf="email">all'indirizzo {{email}}</span>
                </p>
                <p>
                    Controlla la tua posta elettronica e clicca sul link di conferma per attivare il tuo account.
                </p>
                <p>
                    <i class="font-size-14">Se non ricevi nessuna e-mail controlla nella posta indesiderata.</i>
                </p>
            </div>

            <div class="message">
                <button mat-raised-button color="accent" clickAsButton link="/auth/login">
                    TORNA ALLA LOGIN
                </button>
            </div>

        </div>

    </div>

    <div class="onda">
    </div>

</div>
