import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map, mergeMap} from 'rxjs/operators';
import md5 from 'md5';
import {UserStatusEnum} from './user-status.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public user$: Observable<any> = this.user.asObservable().pipe(
        mergeMap(t => {
            if (t == null && this.getUserDataStore() != null) {
                this.setUserData(this.getUserDataStore());
                t = this.getUserDataStore();
            }
            return of(t);
        })
    );
    private avatar: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public avatar$ = this.avatar.asObservable();

    private keyStore = 'salesUserData';

    constructor(
        private http: HttpClient
    ) {
    }

    login(username: string, password: string): Observable<any> {
        const url = `${environment.basePath[environment.env].authService}/oauth/token`;
        const basicAuth = environment.oauthCredential;
        const params = new HttpParams({
            fromObject: {
                username, password: md5(password), scope: environment.scope, grant_type: 'password'
            }
        });

        return this.http.post(url, params, {
            headers: {
                'Authorization': 'Basic ' + btoa(basicAuth.username + ':' + basicAuth.password),
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).pipe(
            mergeMap(t => {
                this.setUserData(t);
                return this.getUserMe();
            })
        );
    }

    public setUserData(data): void {
        const current: any = this.user.getValue();
        const newData = {...current, ...data};
        localStorage.setItem(this.keyStore, JSON.stringify(newData));
        this.user.next(newData);
        return newData;
    }

    getUserMe(): Observable<any> {
        const code = this.getUserDataStore()?.sub;
        if (code) {
            this.getAvatar().subscribe();
            return this.getAmbassadorByCode(code);
        } else {
            return of(null);
        }
    }

    public getUserDataStore(): null | any {
        const data = localStorage.getItem(this.keyStore);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    logout(): void {
        this.cleanData();
    }

    checkLogged(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.getUserMe().subscribe(resp => {
                if (resp) {
                    observer.next(true);
                } else {
                    observer.next(false);
                }
            }, err => {
                observer.next(false);
            });
        });
    }

    checkIsAuthorized(): Observable<boolean> {
        return this.user$.pipe(
            map((t: any) => {
                return (t
                    && ![
                        UserStatusEnum.PROFILO_DA_COMPLETARE,
                        UserStatusEnum.FIRMA_IN_CORSO,
                        UserStatusEnum.IN_CORSO_DI_AUTORIZZAZIONE,
                        UserStatusEnum.PROFILO_COMPLETATO,
                        UserStatusEnum.VARIAZIONE_CONTRATTO
                    ].includes(t?.status));
            })
        );
    }

    private cleanData(): void {
        localStorage.removeItem(this.keyStore);
        this.user.next(null);
        // refresh services
        setTimeout(() => window.location.reload(), 500);
    }

    getAmbassadorCode(): string {
        return this.getUserDataStore()?.codAmbassador;
    }

    saveAmbassador(data): Observable<any> {
        const url = `${environment.basePath[environment.env].userService}/ambassador/update`;
        return this.http.patch(url, data);
    }

    getAmbassadorByCode(code: string): Observable<any> {
        const url = `${environment.basePath[environment.env].userService}/ambassador/${code}`;
        return this.http.get(url).pipe(
            map(t => this.setUserData(t))
        );
    }

    changePassword(data: { oldPassword: string, password: string }): Observable<string> {
        const url = `${environment.basePath[environment.env].userService}/ambassador/change-password`;
        return this.http.post(url, data, {responseType: 'text'});
    }

    saveAvatar(data: { svg: string, }): Observable<any | { codAmbassador: string, avatarSvg: string }> {
        const url = `${environment.basePath[environment.env].userService}/avatar`;
        return this.http.post(url, data.svg);
    }

    setPopupActivationFlag(): Observable<any> {
        const url = `${environment.basePath[environment.env].userService}/ambassador/updatePopupActivationFlag`;
        return this.http.patch(url, {});
    }

    getAvatar(): Observable<any | { codAmbassador: string, avatarSvg: string }> {
        const url = `${environment.basePath[environment.env].userService}/avatar`;
        return this.http.get(url).pipe(
            map((t: any | null) => {
                if (t) {
                    this.avatar.next(t.avatarSvg);
                }
                return t;
            })
        );
    }

}
