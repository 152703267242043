<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="/assets/images/logos/logo.gif">
            </div>

            <div class="title">RECUPERA PASSWORD</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline" *ngIf="!success">

                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matPrefix class="secondary-text mr-8">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email obbligatoria
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Inserisci una valida email
                    </mat-error>
                </mat-form-field>

                <p class="red-900-fg" *ngIf="accountNotExists">Email non valida.</p>

                <button mat-raised-button class="submit-button" color="accent" (click)="resetPassword()"
                        *ngIf="!loading && !success"
                        aria-label="SEND RESET LINK">
                    INVIA LINK DI RESET
                </button>

                <div fxLayoutAlign="center center" *ngIf="loading">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>

                <div fxLayoutAlign="center center" *ngIf="success" class="text-center">
                    <p>Reset password inviato con successo. <br /> Controlla la tua casella di posta, clicca sul link per effettuare il reset.</p>
                </div>

            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">TORNA ALLA LOGIN</a>
            </div>

        </div>

    </div>

    <div class="onda">
    </div>

</div>
