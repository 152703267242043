<div class="terms-condition">
    <h2 class="w-100-p text-center">INFORMATIVA AI SENSI DELL'ART. 13 DEL REGOLAMENTO UE 2016/679<br/>
        RELATIVA AL TRATTAMENTO DEI DATI PERSONALI</h2>

    <p>
        Ai sensi dell'art. 13 del Regolamento UE 2016/679 (di seguito "GDPR” o "Regolamento”), con la presente
        informativa Salesmart S.r.l. con sede legale in Viale Città D'Europa, 681 – 00144 Roma - in qualità di titolare
        del trattamento dei Suoi dati personali (di seguito "Titolare” o " Salesmart), raggiungibile anche via e-mail
        all'indirizzo salesmartsrl@legalmail.it, intende informarLa sulla tipologia di dati raccolti e sulle modalità di
        trattamento adottate.
    </p>

    <h3>Categorie di dati oggetto del trattamento</h3>
    <p>
        Il Titolare tratterà i Suoi dati personali comuni raccolti nell'ambito della registrazione alla piattaforma
        https://ambassador.linkem.digital al fine della creazione del profilo di "Ambassador Linkem” e sottoscrizione
        del contratto di procacciamento di affari e/o della conclusione dello stesso, tra cui rientrano, a titolo
        esemplificativo e non esaustivo nome, cognome, codice fiscale, recapito telefonico, recapito e-mail, indirizzo
        ed in generale i Suoi dati di contatto.
    </p>

    <h3>Finalità, base giuridica e facoltatività del trattamento</h3>
    <p>I Suoi dati personali, saranno trattati senza il Suo consenso per le seguenti finalità:</p>
    <ol>
        <li>
            gestione della creazione dell'account "Ambassador Linkem” sulla piattaforma
            https://ambassador.linkem.digital nonché erogazione del servizio ed esecuzione e gestione del rapporto
            contrattuale (a titolo esemplificativo: i) l'invio di informazioni e/o comunicazioni di servizio connesse
            e/o derivanti dal contratto da Lei concluso; ii) le attività amministrative e contabili connesse
            all'esecuzione del rapporto contrattuale, fra cui la fatturazione dei corrispettivi, la gestione dei
            pagamenti).
            Una volta conferiti, i Suoi dati personali potranno essere trattati anche per le seguenti finalità:
        </li>
        <li>
            adempimento di obblighi di legge, regolamenti o normative nazionali e comunitarie, comprese le disposizioni
            delle Autorità di vigilanza del settore e/o i provvedimenti delle Autorità giudiziarie e/o amministrative
            (tra cui, l'adempimento di obblighi contabili e fiscali, obblighi derivanti dalla DELIBERA n. 46/17/CONS
            AGCOM nonché quelli connessi alle finalità di accertamento e repressione dei reati, di ordine pubblico e di
            protezione civile, anche nell'ambito della lotta al terrorismo);
        </li>
        <li>
            nell'eventualità in cui sia necessario accertare, esercitare o difendere un diritto in sede giudiziaria o in
            ambito stragiudiziale.
        </li>
    </ol>

    <p>
        La base giuridica del trattamento per la finalità a) è individuata nell' art. 6.1.b); il conferimento dei Suoi
        dati personali per tale finalità è facoltativo ma, in difetto, non sarà possibile per Linkem erogare il servizio
        ed adempiere agli ulteriori obblighi assunti nei Suoi confronti.
    </p>
    <p>
        La base giuridica, per la finalità b) è individuata nell' art. 6.1.c); per la finalità c) la base giuridica è da
        rinvenirsi negli artt. 9.2.f) e 6.1.f) del Regolamento, in quanto il trattamento è necessario per soddisfare
        eventuali esigenze difensive di Linkem.
    </p>

    <h3>Modalità di trattamento dei dati</h3>
    <p>
        Il trattamento dei dati personali avverrà tramite supporti e/o strumenti informatici, manuali e/o telematici,
        con
        logiche strettamente correlate alle finalità del trattamento e comunque garantendo la riservatezza e sicurezza
        dei
        dati stessi e nel rispetto del Regolamento e dei Provvedimenti del Garante per la protezione dei Dati Personali
        applicabili.
    </p>

    <h3>Destinatari e trasferimento dei dati personali</h3>
    <p>I Suoi dati personali potranno essere condivisi con:</p>
    <ul>
        <li>
            soggetti terzi che, nell'erogazione dei servizi, agiscono comunemente in qualità di responsabili del
            trattamento
            ai sensi degli artt. 4.8) e 28 del Regolamento (a titolo esemplificativo ma non esaustivo: persone fisiche
            e/o
            società e/o studi professionali che prestano attività di assistenza; persone e/o società e/o studi
            professionali che
            prestano attività di assistenza e consulenza a Salesmart in materia contabile, amministrativa, legale,
            tributaria
            relativamente all'erogazione dei servizi). L'elenco aggiornato e completo dei responsabili del trattamento è
            disponibile presso la funzione Privacy e comunque può essere richiesto al Titolare ai recapiti indicati;
        </li>
        <li>
            soggetti che agiscono in qualità di autonomi titolari del trattamento ai sensi dell'art. 4.7) del
            Regolamento (a
            titolo esemplificativo ma non esaustivo: soggetti che svolgono adempimenti di controllo, revisione e
            certificazione
            delle attività poste in essere da Salesmart);
        </li>
        <li>
            persone autorizzate dal Titolare al trattamento ai sensi degli artt. 29 e 32.4 del Regolamento e
            2-quaterdecies
            del Codice Privacy;
        </li>
        <li>
            soggetti, enti o autorità, autonomi titolari del trattamento, a cui sia obbligatorio comunicare i Suoi dati
            personali in forza di disposizioni di legge e/o di ordini delle autorità.
        </li>
    </ul>

    <h3>Trasferimento dei dati extra UE</h3>
    <p>
        I Suoi dati personali potranno essere trasferiti al di fuori dello Spazio Economico Europeo. Salesmart rende
        noto
        che il trattamento avverrà secondo una delle modalità consentite dalla legge vigente, quali ad esempio il
        consenso
        dell'interessato, l'adozione di Clausole Standard approvate dalla Commissione Europea, la selezione di soggetti
        aderenti a programmi internazionali per la libera circolazione dei dati od operanti in Paesi considerati sicuri
        dalla Commissione Europea con decisione di adeguatezza, nel rispetto delle raccomandazioni 2/2020 dell'European
        Data
        Protection Board. È possibile avere maggiori informazioni, su richiesta, presso il Titolare.
    </p>

    <h3>Conservazione dei dati personali</h3>
    <p>
        I Suoi dati personali saranno conservati solo per il tempo necessario ai fini per cui sono raccolti, rispettando
        i
        principi di minimizzazione e di limitazione della conservazione di cui agli articoli 5.1.c) ed e) del
        Regolamento.
        Il Titolare si riserva, in ogni caso, di conservare i Suoi dati personali anche per adempiere agli obblighi
        normativi cui è soggetto (ad es. obbligo di conservazione delle scritture contabili per 10 anni, decorrenti a
        partire dalla loro registrazione, ai sensi dell'art. 2220 cod. civ.; obblighi fiscali per 5 anni) nonché,
        eventualmente, per tutto il tempo necessario per soddisfare esigenze difensive in giudizio e in ambito
        stragiudiziale.
    </p>
    <h3>I Suoi diritti privacy ex artt. 15 e ss. del Regolamento</h3>
    <p>
        Lei ha diritto di chiedere al Titolare, in qualunque momento, l'accesso ai Suoi Dati ex art. 15 del GDPR, la
        rettifica ex art. 16 del GDPR o la cancellazione degli stessi ex art. 17 del GDPR o di richiedere la limitazione
        del
        trattamento ex art. 18 del GDPR, o di ottenere in un formato strutturato, di uso comune e leggibile da
        dispositivo
        automatico i dati che La riguardano nei casi previsti dall'art. 20 del Regolamento scrivendo a Salesmart al
        recapito
        indicato.
        Le richieste di esercizio dei diritti vanno rivolte per iscritto al Titolare scrivendo a Salesmart S.r.l.
        raggiungibile all'indirizzo e-mail salesmartsrl@legalmail.it.
    </p>

    <h3>Reclamo al Garante</h3>
    <p>
        In ogni caso, qualora Lei ritenga che il trattamento dei dati personali effettuato dal Titolare, avvenga in
        violazione di quanto previsto dalla normativa in vigore, ha il diritto di proporre reclamo all'Autorità Garante
        per
        la Protezione dei Dati Personali, come previsto dall'art. 77 del Regolamento, o di adire le opportune sedi
        giudiziarie ai sensi dell'art. 79 del GDPR.
    </p>
</div>
