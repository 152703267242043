export const Leadorigin = [{
    'id': 1,
    'name': 'WAU',
    'description': 'WAU',
    'value': 'WAU_SALESMART',
    'imageUrl': 'https://storage.googleapis.com/salesmartdp-storage/leadorigin-image/wau-anteprima-leadorigin.png',
    'brandId': 2,
    'commissioning': {
        'id': 1,
        'name': 'Offerta Promo',
        'description': null,
        'collectedAmount': 0.0,
        'totalContracts': 0,
        'leadPaymentAmount': 40.0,
        'contractPaymentAmount': 40.0,
        'minimumAmountThreshold': 3.0,
        'minimumTimeThreshold': 100,
        'primaryColour': '#27AE60',
        'secondaryColour': '#2ECC71',
        'months': null,
        'leadOrigins': null,
        'deleted': false,
        'defaultConfiguration': null
    },
    'type': 'AMBASSADOR'
}];

export const ListObject = [{
    'description': 'WAU',
    'value': 'WAU_SALESMART',
    'type': 'AMBASSADOR',
    'imageUrl': 'https://storage.googleapis.com/salesmartdp-storage/leadorigin-image/wau-anteprima-leadorigin.png'
}];
