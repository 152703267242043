import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'mn_dashboards',
                title    : 'Risultati',
                type     : 'item',
                icon     : 'dashboard',
                url  : '/pages/dashboard',
            },
            {
                id       : 'mn_offers',
                title    : 'Offerte',
                type     : 'item',
                icon     : 'local_offer',
                url  : '/pages/offers',
            },
            {
                id       : 'mn_risultati',
                title    : 'Riepilogo attività',
                type     : 'item',
                icon     : 'euro_symbol',
                url  : '/pages/personal-details',
            },
            {
                id: 'mn_file-manager',
                title: 'Materiali comunicazione',
                translate: 'Materiali di Comunicazione',
                type: 'item',
                icon: 'folder',
                url: '/pages/file-manager'
            },
            {
                id   : 'mn_knowledge-base',
                title: 'Supporto',
                type : 'item',
                icon : 'import_contacts',
                url  : '/pages/knowledge-base'
            }
        ]
    }
];
