export const Performance = {
    'range': 'CUSTOM',
    'from': '2021-11-01',
    'to': '2021-11-30',
    'contractsActivated': [{
        'code': 'C21EJ52889T22232',
        'date': '2021-11-01',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'C21EJ52889T2345345',
        'date': '2021-11-01',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }],
    'contractsTrial': [{
        'code': 'C21EJ52889T22323',
        'date': '2021-11-02',
        'leadOrigin': 'LEAD_AMBASSADOR_B2B'
    }, {
        'code': 'C21EJ52889T2234234',
        'date': '2021-11-04',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'C21EJ52889T2dfdfv',
        'date': '2021-11-05',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }],
    'leadsLavorabili': [{
        'code': 'LEAD0053976923423424324234',
        'date': '2021-11-01',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'LEAD00539769234244234',
        'date': '2021-11-03',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'LEAD00539769fgfdgdf',
        'date': '2021-11-06',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'LEAD00539769fgfdgdf',
        'date': '2021-11-06',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'LEAD00539769rtyh',
        'date': '2021-11-01',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }],
    'leadsAndContractsKO': [{
        'code': 'C21EJ52889T234543533',
        'date': '2021-11-01',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }, {
        'code': 'C21EJ52889T234543534',
        'date': '2021-11-02',
        'leadOrigin': 'LEAD_AMBASSADOR_B2C'
    }]
};
