import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';
import {InfoDialogService} from '@fuse/components/info-dialog/info-dialog.service';
import {UserStatusEnum} from './user-status.enum';
import {DemoWizardService} from '../../apps/demo-wizard/services/demo-wizard.service';

@Injectable({
    providedIn: 'root'
})
export class PermGuard implements CanActivate {

    currentUser: any = null;
    isDemo = false;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _infoDialog: InfoDialogService,
        private _demoWizr: DemoWizardService
    ) {
        this._userService.user$.subscribe(r => this.currentUser = r);
        this._demoWizr.isDemoSubject$.subscribe(r => this.isDemo = r);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userService.checkIsAuthorized().pipe(
            map(t => {
                if (t) {
                    return true;
                }
                if (this.isDemo) {
                    return true;
                }
                if (this.currentUser && this.currentUser?.status === UserStatusEnum.VARIAZIONE_CONTRATTO) {
                    this._infoDialog.showInfoDialog(`
                        Genera e firma il nuovo contratto che riceverai via e-mail per continuare a promuovere le offerte presenti nella piattaforma. <br />
Salesmart premia il tuo impegno: più condividi, più guadagni!
                        `, 'OK', 'ATTENZIONE!', '600px');
                } else if (this.currentUser && this.currentUser?.status !== UserStatusEnum.PROFILO_DA_COMPLETARE) {
                    this._infoDialog.showInfoDialog(`
                        Il tuo profilo è in fase di
                        approvazione.<br /> Se non hai ancora
                        firmato il contratto Ambassador ti chiediamo
                        di procedere alla firma elettronica.<br />
                        Se hai già provveduto ti
                        chiediamo di attendere la nostra
                        approvazione.<br />Riceverai una
                        e-mail con la conferma
                        dell'attivazione del tuo account.
                        `, 'OK', 'ATTENZIONE!', '600px');
                } else if (this.currentUser && this.currentUser?.status === UserStatusEnum.PROFILO_DA_COMPLETARE) {
                    this._infoDialog.showInfoDialog(`
                        Ti ricordiamo che per utilizzare il tuo account Ambassador e promuovere le offerte Linkem a tutti i tuoi amici è necessario:<br />
                        <ul>
                            <li>Compilare la sezione Profilo con le tue Informazioni Personali</li>
                            <li>Indicare le tue coordinate bancarie per ricevere le ricompense</li>
                            <li>Generare e firmare il contratto che riceverai via e-mail</li>
                        </ul>
                        `, 'OK', 'ATTENZIONE!', '600px');
                }
                return this._router.createUrlTree(['/pages/profile']);
            })
        );
    }

}
