import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable()
export class EventListenerService {

    private renderer: Renderer2;
    private resizeHandler: () => void = null;

    resizeEvent: Subject<any> = new Subject<any>();

    constructor(
        private readonly renderFactory: RendererFactory2
    ) {
        this.renderer = this.renderFactory.createRenderer(null, null);
    }

    startListeningResizeEvent(): void {
        this.stopListening();
        this.resizeHandler = this.renderer.listen('window', 'resize', evt => {
            this.resizeEvent.next(evt);
        });
    }

    stopListening(): void {
        if (this.resizeHandler) {
            this.resizeHandler();
            this.resizeHandler = null;
        }
    }

}