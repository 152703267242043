// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,

    env: 'dev',
    scope: 'salesmart_ambassador',
    oauthCredential: {
        username: 'app-angular-003',
        password: 'Dnfhjv@Dnfhjv@12dfhwefw2345wfwe'
    },

    basePath : {
        prod: {
            authService: 'https://sts.salesmart.it',
            userService: 'https://user.salesmart.it',
            campaignService: 'https://fbadv.salesmart.it',
            landingService: 'https://landing.salesmart.it',
            communicationService: 'https://communication.salesmart.it',
            barcodeService: 'https://barcodes.salesmart.it',
            eclService: 'https://ecl.salesmart.it',
            exportExcel: 'https://excelexport.salesmart.it',
            pwdService: 'https://pwd.salesmart.it',
            esignService: 'https://esign.salesmart.it',
            paymentService: 'https://payment.salesmart.it'
        },
        dev: {
            authService: 'https://ststest.salesmart.it',
            userService: 'https://usertest.salesmart.it',
            campaignService: 'https://fbadvtest.salesmart.it',
            landingService: 'https://landingtest.salesmart.it',
            communicationService: 'https://communicationtest.salesmart.it',
            barcodeService: 'https://barcodestest.salesmart.it',
            eclService: 'https://ecltest.salesmart.it',
            exportExcel: 'https://excelexporttest.salesmart.it',
            pwdService: 'https://pwdtest.salesmart.it',
            esignService: 'https://esigntest.salesmart.it',
            paymentService: 'https://paymenttest.salesmart.it'
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
