import {Injectable, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuseInfoDialogComponent} from './info-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class InfoDialogService {

    constructor(
        private _matDialog: MatDialog
    ) {
    }

    showInfoDialog(message: string, okLabel?: string, titleLabel?: string, width?: string): MatDialogRef<FuseInfoDialogComponent> {
        return this._matDialog.open(FuseInfoDialogComponent, {
            panelClass: 'fuse-info-dialog',
            width: width || '280px',
            data: {
                message,
                okLabel,
                titleLabel
            }
        });
    }

    showInfoDialogTemplate(data: {
        template: TemplateRef<any>,
        okLabel?: string,
        titleLabel?: string,
        width?: string,
        templateActions?: TemplateRef<any>
    }): MatDialogRef<FuseInfoDialogComponent> {
        return this._matDialog.open(FuseInfoDialogComponent, {
            panelClass: 'fuse-info-dialog',
            width: data.width || '280px',
            data: {
                template: data.template,
                templateActions: data.templateActions,
                okLabel: data.okLabel,
                titleLabel: data.titleLabel
            }
        });
    }

}
