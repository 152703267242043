import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FAQ_CATEGORIES, FaqEnum, FaqService} from '../../services/faq.service';
import {ListModel} from '@fuse/models/list.model';
import {FaqBaseArticleComponent} from './dialogs/article/article.component';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin, Observable} from 'rxjs';


@Component({
    selector: '[app-faq-list]',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent implements AfterViewInit {

    @Input()
    filters: FAQ_CATEGORIES[] = ['ALL'];

    data: Array<{key: string, value: ListModel<any>}> = [];
    faqCategories = Object.keys(FaqEnum);

    constructor(
        private _faqService: FaqService,
        private _matDialog: MatDialog,
    ) {
    }

    ngAfterViewInit(): void {
        this.getFaqs();
    }

    getFaqs(): void {
        let faqs$: Observable<ListModel<{answer: string, id: number, question: string, type: string}>>[];
        if (this.filters.includes('ALL')) {
            faqs$ = this.faqCategories.map(it => this._faqService.getList(it));
        } else {
            faqs$ = this.filters.map(it => this._faqService.getList(it));
        }
        forkJoin(faqs$).subscribe({
            next: (value) => {
                this.data = value
                    .filter(r => r.content.length > 0)
                    .map(r => ({key: r.content[0].type, value: r}));
            }
        });
    }

}
