import {
    BackendService,
    HeadersCore,
    ParsedRequestUrl,
    PassThruBackend,
    removeTrailingSlash,
    ResponseOptions,
    STATUS
} from 'angular-in-memory-web-api';
import {Injectable} from '@angular/core';
import {InMemoryBackendConfigArgs, InMemoryDbService, RequestInfo} from 'angular-in-memory-web-api/interfaces';
import {
    HttpBackend,
    HttpEvent,
    HttpHeaders,
    HttpParams,
    HttpRequest,
    HttpResponse,
    HttpXhrBackend,
    XhrFactory
} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {getStatusText, isSuccess} from 'angular-in-memory-web-api/http-status-codes';

export const httpClientInMemBackendServiceFactory = (dbService, options, xhrFactory) => {
    return new HttpClientBackendCustomService(dbService, options, xhrFactory);
};

@Injectable({
    providedIn: 'root'
})
export class HttpClientBackendCustomService extends BackendService implements HttpBackend {

    constructor(
        inMemDbService: InMemoryDbService,
        config: InMemoryBackendConfigArgs,
        private xhrFactory: XhrFactory
    ) {
        super(inMemDbService, config);
    }

    protected createHeaders(headers: { [p: string]: string }): HeadersCore {
        return new HttpHeaders(headers);
    }

    protected createPassThruBackend(): PassThruBackend {
        try {
            return new HttpXhrBackend(this.xhrFactory);
        } catch (ex) {
            ex.message = 'Cannot create passThru404 backend; ' + (ex.message || '');
            throw ex;
        }
    }

    protected createQueryMap(search: string): Map<string, string[]> {
        const data: Map<string, string[]> = new Map();
        if (search) {
            const param = new HttpParams({fromString: search});
            param.keys().forEach((p) => data.set(p, param.getAll(p)));
        }
        return data;
    }

    protected createResponse$fromResponseOptions$(resOptions$: Observable<ResponseOptions>): Observable<any> {
        return resOptions$.pipe(map((opts) => new HttpResponse(opts as any)));
    }

    protected getJsonBody(req: any): any {
        return req.body;
    }

    protected getRequestMethod(req: any): string {
        return req?.method?.toLowerCase() ?? 'get';
    }

    public handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        try {
            return this.handleRequest(req);
        } catch (e) {
            const err = e.message || e;
            const resOption = this.createErrorResponseOptions(req.url, STATUS.INTERNAL_SERVER_ERROR, '' + err);
            return this.createResponse$(() => resOption);
        }
    }

    protected parseRequestUrl(url: string): ParsedRequestUrl {
        try {
            const loc = this.getLocation(url);
            let drop = this.config.rootPath.length;
            let urlRoot = '';
            if (loc.host !== this.config.host) {
                // url for a server on a different host!
                // assume it's collection is actually here too.
                drop = 1; // the leading slash
                urlRoot = loc.protocol + '//' + loc.host + '/';
            }
            const path = loc.path.substring(drop);
            const pathSegments = path.split('/');
            let segmentIx = 0;
            // apiBase: the front part of the path devoted to getting to the api route
            // Assumes first path segment if no config.apiBase
            // else ignores as many path segments as are in config.apiBase
            // Does NOT care what the api base chars actually are.
            let apiBase = void 0;
            // tslint:disable-next-line:triple-equals
            if (this.config.apiBase == undefined) {
                apiBase = pathSegments[segmentIx++];
            } else {
                apiBase = removeTrailingSlash(this.config.apiBase.trim());
                if (apiBase) {
                    segmentIx = apiBase.split('/').length;
                } else {
                    segmentIx = 0; // no api base at all; unwise but allowed.
                }
            }
            apiBase += '/';
            let collectionName = pathSegments[segmentIx++];
            collectionName = pathSegments.join('/');
            // ignore anything after a '.' (e.g.,the "json" in "customers.json")
            collectionName = collectionName && collectionName.split('.')[0];
            const id = pathSegments[segmentIx++];
            const query = this.createQueryMap(loc.query);
            const resourceUrl = urlRoot + apiBase + collectionName + '/';
            return {apiBase: apiBase, collectionName: collectionName, id: id, query: query, resourceUrl: resourceUrl};
        } catch (err) {
            const msg = `unable to parse url '${url}'; original error ${err.message}`;
            throw new Error(msg);
        }
    }

    protected post({collection, collectionName, headers, id, req, resourceUrl, url}: RequestInfo): ResponseOptions {
        const item = this.clone(this.getJsonBody(req));
        // tslint:disable-next-line:triple-equals
        if (item.id == undefined) {
            try {
                item.id = id || this.genId(collection, collectionName);
            } catch (err) {
                const emsg = err.message || '';
                if (/id type is non-numeric/.test(emsg)) {
                    return this.createErrorResponseOptions(url, STATUS.UNPROCESSABLE_ENTRY, emsg);
                } else {
                    console.error(err);
                    return this.createErrorResponseOptions(url, STATUS.INTERNAL_SERVER_ERROR, 'Failed to generate new id for \'' + collectionName + '\'');
                }
            }
        }
        if (id && id !== item.id) {
            return this.createErrorResponseOptions(url, STATUS.BAD_REQUEST, 'Request id does not match item.id');
        } else {
            id = item.id;
        }
        const existingIx = this.indexOf(collection, id);
        const body = this.bodify(this.clone(collection));
        return {headers: headers, body: body, status: STATUS.OK};
    }

    // @ts-ignore
    protected get({collection, collectionName, headers, id, query, url}: RequestInfo): ResponseOptions | Blob {
        const data = collection;
        if (!data) {
            return this.createErrorResponseOptions(url, STATUS.NOT_FOUND, '\'' + collectionName + '\' with id=\'' + id + '\' not found');
        }
        let body;
        if (data instanceof Blob) {
            body = data;
        } else {
            body = this.bodify(this.clone(data));
        }
        return {
            body: body,
            headers: headers,
            status: STATUS.OK
        };
    }


    // @ts-ignore
    protected createResponseOptions$(resOptionsFactory: () => ResponseOptions): Observable<ResponseOptions | Blob> {
        return new Observable((responseObserver) => {
            let resOptions;
            try {
                resOptions = resOptionsFactory();
            } catch (error) {
                const err = error.message || error;
                resOptions = this.createErrorResponseOptions('', STATUS.INTERNAL_SERVER_ERROR, '' + err);
            }
            if (!(resOptions instanceof Blob)) {
                const status = resOptions.status;
                try {
                    resOptions.statusText = getStatusText(status);
                } catch (e) { /* ignore failure */
                }

                if (isSuccess(status)) {
                    responseObserver.next(resOptions);
                    responseObserver.complete();
                } else {
                    responseObserver.error(resOptions);
                }

            } else {
                responseObserver.next(resOptions);
                responseObserver.complete();
            }
        });
    }

    protected indexOf(collection: any[], id: number): number {
        if (_.isArray(collection)) {
            return super.indexOf(collection, id);
        }
        return -1;
    }

    protected resetDb(reqInfo?: RequestInfo): Observable<boolean> {
        this.dbReadySubject.next(false);
        const db = this.inMemDbService.createDb(reqInfo);
        const db$ = db instanceof Observable
            ? db
            : db instanceof Promise
                ? from(db)
                : of(db);
        db$.subscribe((d) => {
            this.db = d;
            this.dbReadySubject.next(true);
        });
        return this.dbReady;
    }

}
