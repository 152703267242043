import {NgModule} from '@angular/core';
import {AvatarShowingComponent} from './avatar-showing.component';
import {FuseSharedModule} from "../../../../@fuse/shared.module";


@NgModule({
    declarations: [AvatarShowingComponent],
    imports: [
        FuseSharedModule
    ],
    exports: [
        AvatarShowingComponent
    ]
})
export class AvatarShowingModule {
}
