export const locale = {
    lang: 'it',
    data: {
        'ENUM': {
            'AVATARS': {
                'ACCESSORIES': {
                    'BLANK': 'Nessun accessorio',
                    'KURTA': 'Occhiali',
                    'PRESCRIPTION01': 'Occhiali trasparenti 1',
                    'PRESCRIPTION02': 'Occhiali trasparenti 2',
                    'ROUND': 'Occhiali rotondi',
                    'SUNGLASSES': 'Occhiali da sole 1',
                    'WAYFARERS': 'Occhiali da sole 2',
                },
                'CLOTHES_COLOR': {
                    'BLACK': 'Nero',
                    'BLUE1': 'Azzurro',
                    'BLUE2': 'Blu',
                    'BLUE3': 'Blu scuro',
                    'GRAY1': 'Grigio chiaro',
                    'GRAY2': 'Grigio scuro',
                    'HEATHER': 'Blu scuro',
                    'PASTEL_BLUE': 'Blu pastello',
                    'PASTEL_GREEN': 'Verde pastello',
                    'PASTEL_ORANGE': 'Arancione pastello',
                    'PASTEL_RED': 'Rosso pastello',
                    'PASTEL_YELLOW': 'Giallo pastello',
                    'PINK': 'Rosa',
                    'RED': 'Rosso',
                    'WHITE': 'Bianco',
                },
                'CLOTHES': {
                    'BLAZER_SHIRT': 'Giacca con maglietta',
                    'BLAZER_SWEATER': 'Giacca con maglione',
                    'COLLAR_SWEATER': 'Maglione',
                    'GRAPHIC_SHIRT': 'Maglietta con disegno',
                    'HOODIE': 'Felpa',
                    'OVERALL': 'Canottiera',
                    'SHIRT_SCOOPNECK': 'Maglietta scollo a giro',
                    'SHIRT_VNECK': 'Maglietta scollo a V',
                },
                'EYES': {
                    'CLOSE': 'Chiusi',
                    'CRY': 'Triste',
                    'NONE': 'Normale',
                    'DIZZY': 'Stupito',
                    'EYEROLL': 'Disapprovo',
                    'HAPPY': 'Felice',
                    'HEARTS': 'Cuori',
                    'SIDE': 'Irritato',
                    'SQUINT': 'Sarcasmo',
                    'SURPRISED': 'Sorpreso',
                    'WINK': 'Occhiolino 1',
                    'WINKWACKY': 'Occhiolino 2',
                },
                'EYEBROW': {
                    'ANGRY' : 'Arrabbiato',
                    'ANGRY_NATURAL' : 'Molto arrabiato',
                    'NONE' : 'Normali',
                    'DEFAULT_NATURAL' : 'Normali 1',
                    'FLAT_NATURAL' : 'Folte lunghe',
                    'RAISED_EXCITED' : 'Tonde sottili',
                    'RAISED_EXCITED_NATURAL' : 'Tonde',
                    'SAD_CONCERNED' : 'Triste',
                    'SAD_CONCERNED_NATURAL' : 'Molto triste',
                    'UNIBROW_NATURAL' : 'Folte',
                    'UPDOWN' : 'Su giù',
                    'UPDOWN_NATURAL' : 'su giù 1',
                },
                'FACIAL_HAIR_COLOR': {
                    'AUBURN': 'Castano chiaro',
                    'BLACK': 'Nero',
                    'BLONDE': 'Biondo',
                    'BLONDE_GOLDEN': 'Oro',
                    'BROWN': 'Castano',
                    'BROWN_DARK': 'Castano scuro',
                    'PASTEL_PINK': 'Rosa pastello',
                    'PLATINUM': 'Platino',
                    'RED': 'Rosso',
                    'SILVER_RED': 'Rosa',
                },
                'FACIAL_HAIR': {
                    'BLANK': 'Senza',
                    'BEARD_MEDIUM': 'Folta',
                    'BEARD_LIGHT': 'Corta rasata',
                    'BEARD_MAGESTIC': 'Lunga',
                    'MOUSTACHE_FANCY': 'Baffi 1',
                    'MOUSTACHE_MAGNUM': 'Baffi 2'
                },
                'GRAPHIC': {
                    'BAT': 'Pipistrello',
                    'CUMBIA': 'Cumbia',
                    'DEER': 'Cervo',
                    'DIAMOND': 'Diamante',
                    'HOLA': 'Hola',
                    'PIZZA': 'Pizza',
                    'RESIST': 'Resist',
                    'SELENA': 'Selena',
                    'BEAR': 'Orso',
                    'SKULLOUTLINE': 'Teschio 1',
                    'SKULL': 'Teschio 2'
                },
                'HAIR_COLOR': {
                    'AURBURN': 'Castano chiaro',
                    'BLACK': 'Neri',
                    'BLONDE': 'Biondi',
                    'BLONDEGOLDEN': 'Oro',
                    'BROWN': 'Castano',
                    'BROWNDARK': 'Castano scuro',
                    'PASTELPINK': 'Rosa pastello',
                    'PLATINUM': 'Platino',
                    'RED': 'Rosso',
                    'SILVERGRAY': 'Grigio chiaro',
                },
                'HAT_COLOR': {
                    'BLACK' : 'Nero',
                    'BLUE01' : 'Azzurro',
                    'BLUE02' : 'Blu',
                    'BLUE03' : 'Blu scuro',
                    'GRAY01' : 'Gricio chiaro',
                    'GRAY02' : 'Grigio scuro',
                    'HEATHER' : 'Grigio blu',
                    'PASTEL_BLUE' : 'Blu pastello',
                    'PASTEL_GREEN' : 'Verde pastello',
                    'PASTEL_ORANGE' : 'Arancione pastello',
                    'PASTEL_RED' : 'Rosso pastello',
                    'PASTEL_YELLOW' : 'Giallo pastello',
                    'PINK' : 'Rosa',
                    'RED' : 'Rosso',
                    'WHITE' : 'Bianco',
                },
                'MOUTH': {
                    'CONCERENED' : 'Deluso',
                    'NONE' : 'Normale',
                    'DISBELIEF' : 'Incredulo',
                    'EATING' : 'Mangiando',
                    'GRIMFACE' : 'Imbarazzato',
                    'SAD' : 'Triste',
                    'SCREAM_OPEN' : 'Molto deluso',
                    'SERIOUS' : 'Serio',
                    'SMILE' : 'Sorriso',
                    'TONGUE' : 'Linguaccia',
                    'TWINKLE' : 'Felice',
                    'VOMIT' : 'Vomito',
                },
                'SKIN': {
                    'TANNED' : 'Abbronzato',
                    'YELLOW' : 'Gialla',
                    'PALE' : 'Bianca',
                    'LIGHT' : 'Chiara',
                    'BROWN' : 'Scura',
                    'DARK_BROWN' : 'Molto scura',
                    'BLACK' : 'Nera',
                },
                'TOP': {
                    'NO_HAIR': 'Senza Capelli',
                    'EYEPATCH': 'Benda sull\'occhio',
                    'HAT': 'Cappello',
                    'HIJAB': 'Velo',
                    'TURBAN': 'Turbante',
                    'WINTER_HAT1': 'Cappello invernale 1',
                    'WINTER_HAT2': 'Cappello invernale 2',
                    'WINTER_HAT3': 'Cappello invernale 3',
                    'WINTER_HAT4': 'Cappello invernale 4',
                    'LONGHAIR_BIGHAIR': 'Capelli lunghi 1',
                    'LONGHAIR_BOB': 'Capelli lunghi 2',
                    'LONGHAIR_BUN': 'Capelli lunghi 3',
                    'LONGHAIR_CURLY': 'Capelli lunghi 4',
                    'LONGHAIR_CURVY': 'Capelli lunghi 5',
                    'LONGHAIR_DREADS': 'Capelli lunghi 6',
                    'LONGHAIR_FRIDA': 'Capelli lunghi 7',
                    'LONGHAIR_FRO': 'Capelli lunghi 8',
                    'LONGHAIR_FROBAND': 'Capelli lunghi 9',
                    'LONGHAIR_NOTTOOLONG': 'Capelli lunghi 10',
                    'LONGHAIRS_HAVEDSIDES': 'Capelli lunghi 11',
                    'LONGHAIR_MIAWALLACE': 'Capelli lunghi 12',
                    'LONGHAIR_STRAIGHT': 'Capelli lunghi 13',
                    'LONGHAIR_STRAIGHT2': 'Capelli lunghi 14',
                    'LONGHAIR_STRAIGHTSTRAND': 'Capelli lunghi 15',
                    'SHORTHAIR_DREADS01': 'Capelli corti 1',
                    'SHORTHAIR_DREADS02': 'Capelli corti 2',
                    'SHORTHAIR_FRIZZLE': 'Capelli corti 3',
                    'SHORTHAIR_SHAGGYMULLET': 'Capelli corti 4',
                    'SHORTHAIR_SHORTCURLY': 'Capelli corti 5',
                    'SHORTHAIR_SHORTFLAT': 'Capelli corti 6',
                    'SHORTHAIR_SHORTROUND': 'Capelli corti 7',
                    'SHORTHAIR_SHORTWAVED': 'Capelli corti 8',
                    'SHORTHAIR_SIDES': 'Calvo',
                    'SHORTHAIR_THECAESAR': 'Capelli corti 9',
                    'SHORTHAIR_THECAESARSIDEPART': 'Capelli corti 10',
                }
            },
            'ACCOUNT': {
                'STATUS': {
                    'DA_ATTIVARE': 'Profilo da completare',
                    'PROFILO_DA_COMPLETARE': 'Profilo da completare',
                    'PROFILO_COMPLETATO': 'Profilo da abilitare, contratto da generare',
                    'FIRMA_IN_CORSO': 'Firma in corso',
                    'IN_CORSO_DI_AUTORIZZAZIONE': 'In corso di autorizzazione.',
                    'ATTIVO': 'Attivo',
                    'BLOCCATO': 'Bloccato',
                    'CANCELLATO': 'Cancellato',
                    'VARIAZIONE_CONTRATTO': 'Variazione contratto'
                }
            },
            'CONTRACT': {
                'STATUS': {
                    'PROFILE_TO_BE_COMPLETE': 'Profilo da completare',
                    'CONTRACT_CAN_BE_GENERATE': 'Il contratto può essere generato',
                    'CONTRACT_TO_BE_SIGN': 'Il contratto è in attesa di firma',
                    'CONTRACT_WAITING_TO_APPROVE': 'Contratto in attesa di approvazione',
                    'CONTRACT_SIGN': 'Contratto firmato'
                }
            },
            'PAYMENT': {
                'STATUS': {
                    'TO_BE_SIGNED': 'Da firmare',
                    'SIGNING': 'Firma in corso',
                    'IN_PAYMENT': 'In pagamanto',
                    'PAID': 'Pagato',
                    'REJECTED': 'Rifiutato',
                    'REQUEST_INVOICE': 'Richiesta Fattura',
                    'INVOICE_PAID': 'Fattura pagata'
                }
            },
            'FILEMANAGER': {
                'FILETYPE': {
                    'IMAGE': 'Immagine',
                    'FILE': 'File',
                    'ARCHIVE': 'Archivio Compresso',
                    'PICTURE_AS_PDF': 'PDF',
                },
                'PLATFORM_TYPE': {
                    'AMBASSADOR': 'Ambassador',
                    'DIGITAL_PLATFORM': 'Digital Business Platform'
                }
            },
            'FAQ': {
                'AMBASSADOR_GENERAL': 'Domande frequenti',
                'AMBASSADOR_REGISTRATION': 'Gestione Account',
                'AMBASSADOR_CONTRACT': 'Contratto e adempimenti fiscali',
                'AMBASSADOR_ACTIVITY': 'Attività dell\'Ambassador',
                'AMBASSADOR_PAYMENT': 'Ambassador pagamenti e rendicontazione',
                'AMBASSADOR_PUBLIC': 'Domande frequenti'
            }
        }
    }
};
