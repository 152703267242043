import {Component, TemplateRef, ViewChild} from '@angular/core';
import {InfoDialogService} from '../../../../@fuse/components/info-dialog/info-dialog.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    @ViewChild('privacy')
    privacyTemplate: TemplateRef<any>;

    @ViewChild('cookie')
    cookieTemplate: TemplateRef<any>;

    @ViewChild('terms')
    termsTemplate: TemplateRef<any>;

    constructor(
        private _infoDialogService: InfoDialogService
    ) {
    }

    openPrivacy(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.privacyTemplate,
            width: '600px',
            okLabel: 'Continua',
            titleLabel: 'Privacy Policy'
        });
    }

    openCookie(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.cookieTemplate,
            width: '600px',
            okLabel: 'Continua',
            titleLabel: 'Cookie Policy'
        });
    }

    openTerms(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.termsTemplate,
            width: '600px',
            okLabel: 'Continua',
            titleLabel: 'Terms and Condition'
        });
    }

}
