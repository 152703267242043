import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import md5 from 'md5';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {UserService} from '../../services/auth/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FuseSplashScreenService} from '../../../../@fuse/services/splash-screen.service';
import {mergeMap} from 'rxjs/operators';
import {InfoDialogService} from '../../../../@fuse/components/info-dialog/info-dialog.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {PwdService} from '../../services/auth/pwd.service';
import {UserStatusEnum} from '../../services/auth/user-status.enum';
import {Utilities} from '../../../../@fuse/utils/utilities.model';
import {FusePerfectScrollbarService} from '../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    registerForm: FormGroup;

    showPassword = false; 

    loading = false;
    alreadyExists = false;

    show = 'login';

    @ViewChild('termsRef')
    termsTemplate: TemplateRef<any> = null;

    @ViewChild('privacy')
    privacyTemplate: TemplateRef<any>;

    @ViewChild('cookie')
    cookieTemplate: TemplateRef<any>;

    @ViewChild('showMore')
    showMoreTemplate: TemplateRef<any> = null;

    @ViewChild('showMoreActions')
    showMoreActions: TemplateRef<any>;

    @ViewChild('faqList')
    faqListTemplate: TemplateRef<any>;

    @ViewChild('invalidCredential')
    invalidCredentialTemplate: TemplateRef<any>;

    @ViewChild('emailNotActive')
    emailNotActiveTemplate: TemplateRef<any>;

    resendEmailConfirmationLoading = false;
    resendEmailConfirmationMessage: string = null;

    invalidCredential = false;

    private subscription: Subscription;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {UserService} _userService
     * @param {Router} _router
     * @param {FuseSplashScreenService} _loaderService
     * @param _infoDialogService
     * @param _activatedRoute
     * @param _pwdService
     * @param fuseScrollBar
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private _router: Router,
        private _loaderService: FuseSplashScreenService,
        private _infoDialogService: InfoDialogService,
        private _activatedRoute: ActivatedRoute,
        private _pwdService: PwdService,
        private fuseScrollBar: FusePerfectScrollbarService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this._pwdService.origin = this._activatedRoute.snapshot.queryParamMap.get('origin');

        this.registerForm = this._formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(100), Utilities.noWhitespaceValidator]],
            lastName: ['', [Validators.required, Validators.maxLength(100), Utilities.noWhitespaceValidator]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20), Utilities.noWhitespaceValidator]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            terms: [null, Validators.required],
            'privacy-policy': [null, Validators.required],
        });
    }

    register(): void {
        if (this.registerForm.valid) {
            this.loading = true;
            const data = this.registerForm.getRawValue();
            data.password = md5(data.password);
            delete data.passwordConfirm;
            data.name = _.capitalize(data.name);
            data.lastName = _.capitalize(data.lastName);
            this._pwdService.newAmbassadorAccount(data).subscribe(resp => {
                this._router.navigate(['/auth/mail-confirm', data.email]);
                this.loading = false;
            }, (err: HttpErrorResponse | any) => {
                this.loading = false;
                if (err instanceof HttpErrorResponse && err.status === 409) {
                    this.alreadyExists = true;
                }
            });
        }
        this.registerForm.get('terms').markAsTouched();
        this.registerForm.get('privacy-policy').markAsTouched();
    }

    openTerms(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.termsTemplate,
            okLabel: 'OK',
            titleLabel: 'Termini e Condizioni d\'uso',
            width: '500px'
        });
    }

    openPrivacy(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.privacyTemplate,
            okLabel: 'Continua',
            width: '600px',
            titleLabel: 'Privacy Policy'
        });
    }

    openCookie(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.cookieTemplate,
            width: '600px',
            okLabel: 'Continua',
            titleLabel: 'Cookie Policy'
        });
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line:no-unused-expression
        this.subscription && this.subscription.unsubscribe();
    }

    login(): void {
        if (this.loginForm.valid) {
            const data = this.loginForm.getRawValue();
            this._loaderService.show();
            this.subscription = this._userService.login(data.email, data.password)
                .pipe(
                    mergeMap(() => {
                        return this._userService.checkIsAuthorized();
                    })
                )
                .subscribe(r => {
                    if (r || this._userService?.getUserDataStore()?.status === UserStatusEnum.VARIAZIONE_CONTRATTO) {
                        this._router.navigate(['/pages/dashboard']);
                    } else {
                        this._router.navigate(['/pages/profile']);
                    }
                    this._loaderService.hide();
                }, (err: any | HttpErrorResponse) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400 && err?.error?.error_description === 'EMAIL_NOT_VERIFIED') {
                            this._infoDialogService.showInfoDialogTemplate({
                                template: this.emailNotActiveTemplate,
                                okLabel: 'OK',
                                titleLabel: 'ATTENZIONE!'
                            });
                            this._loaderService.hide();
                            return;
                        }
                        if (err.status === 400) {
                            this._infoDialogService.showInfoDialogTemplate({
                                template: this.invalidCredentialTemplate,
                                okLabel: 'OK',
                                titleLabel: 'ATTENZIONE!'
                            });
                            this._loaderService.hide();
                            return;
                        }
                        this._infoDialogService.showInfoDialog(`
                            <h3>Ops c'è stato un errore</h3>
                            <p>Ti preghiamo di riprovare tra qualche minuto, se l'errore persiste, chiamaci al: +39 0805622065</p>
                        `, 'Continua', 'Errore Login');
                    }
                    this._loaderService.hide();
                });
        }
    }

    showDown(): void {
        this.fuseScrollBar.getByKey('fusePerfectScrollbarContainer3').scrollTo(0, 730, 500);
    }

    openShowMore(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.showMoreTemplate,
            templateActions: this.showMoreActions,
            okLabel: 'CONTINUA',
            titleLabel: 'DIVENTA AMBASSADOR',
            width: '1000px'
        });
    }

    openFaq(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.faqListTemplate,
            okLabel: 'Continua',
            width: '600px',
            titleLabel: 'Faq'
        });
    }

    hasPartner(): boolean {
        const origin = this._pwdService.origin ?? '';
        return origin !== '';
    }

    partnerLogo(): string | null {
        if (this.hasPartner()) {
            const origin = this._pwdService.origin?.toLowerCase() ?? null;
            return `/assets/images/partner/${origin}.png`;
        }
        return null;
    }

}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return {passwordsNotMatching: true};
};
