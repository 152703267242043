import {Injectable} from '@angular/core';
import {FusePerfectScrollbarDirective} from './fuse-perfect-scrollbar.directive';

@Injectable({
    providedIn: 'root'
})
export class FusePerfectScrollbarService {

    private _map: Map<string, FusePerfectScrollbarDirective> = new Map<string, FusePerfectScrollbarDirective>();

    addNewScrollBar(key: string, directive: FusePerfectScrollbarDirective): void {
        this._map.set(key, directive);
    }

    getByKey(key: string): FusePerfectScrollbarDirective {
        return this._map.get(key);
    }

}
