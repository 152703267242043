<div class="cookie-banner p-16" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center"
     fxLayoutGap="15px" *ngIf="isVisible">

    <p class="text-justify">Il sito utilizza cookies per abilitare servizi e funzionalità essenziali sulla nostra
        applicazione. Cliccando Accetto o chiudendo questo banner acconsenti al nostro utilizzo dei cookies. Per sapere
        di più sul nostro utilizzo di cookies, per favore visita le nostre Cookie Policy e Privacy Policy.</p>
    <div fxLayout="row" fxLayoutGap="7px">
        <button mat-raised-button color="accent" (click)="addCookie()">ACCETTA</button>
        <button mat-raised-button color="primary" (click)="openCookie()">VISUALIZZA</button>
    </div>

</div>

<ng-template #cookieRef>
    <div app-cookie-policy></div>
</ng-template>