<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="/assets/images/logos/logo.gif">
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="Email">
                    <mat-icon matPrefix class="secondary-text mr-8">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        Email obbligatoria
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
                        Inserire una mail valida
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" placeholder="Password">
                    <mat-icon matPrefix class="secondary-text mr-8">vpn_key</mat-icon>
                    <mat-error>
                        Password obbligatoria
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Conferma Password</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm" placeholder="Conferma Password">
                    <mat-icon matPrefix class="secondary-text mr-8">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Conferma password obbligatoria
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Le password non coincidono
                    </mat-error>
                </mat-form-field>

                <p class="font-weight-600" *ngIf="resetOk">Reset Password avvenuto con successo, tra qualche secondo verrai reindirizzato alla login page</p>

                <button *ngIf="!resetOk && !notValid" mat-raised-button class="submit-button" color="accent" (click)="resetPassword()"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid">
                    RESETTA PASSWORD
                </button>

                <p *ngIf="notValid" class="red-700-fg font-weight-bolder text-center">
                    Link di reset non valido, controlla che tu abbia cliccato correttamente sul link della mail!
                </p>

            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">TORNA ALLA LOGIN</a>
            </div>

        </div>
        
    </div>

    <div class="onda">
    </div>

</div>
