import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBannerComponent } from './cookie-banner.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {PolicyModule} from '../../../main/apps/policy/policy.module';
import {CookieService} from 'ngx-cookie-service';



@NgModule({
    declarations: [CookieBannerComponent],
    exports: [
        CookieBannerComponent
    ],
    imports: [
        FuseSharedModule,
        PolicyModule
    ],
    providers: [CookieService]
})
export class CookieBannerModule { }
