import {ElementRef, Renderer2, RendererFactory2} from '@angular/core';
import {ELEMENT_BODY, IStepInterface} from './step.interface';

export class DrawOverlayService {

    private _topHeight = 0;
    private _leftWidth = 0;
    private _rightWidth = 0;
    private _bottomHeight = 0;

    private backdropTop: any = null;
    private backdropLeft: any = null;
    private backdropRight: any = null;
    private backdropBottom: any = null;

    private renderer: Renderer2 = null;

    private backDropBackgroundColor = `rgba(0, 0, 0, 0.8)`;

    constructor(
        private data: { el: ElementRef, backdropElement: HTMLElement },
        private rendererFactory: RendererFactory2
    ) {
    }

    getRenderer(): Renderer2 {
        if (!this.renderer) {
            this.renderer = this.rendererFactory.createRenderer(null, null);
        }
        return this.renderer;
    }

    getCurrentElement(): ElementRef {
        return this.data.el;
    }

    getContainerBackdrop(): HTMLElement {
        return this.data.backdropElement;
    }

    draw(step: IStepInterface): void {

        this._topHeight = this.getTopHeight();
        this._leftWidth = this.getLeftWidth();
        this._rightWidth = this.getRightWidth();
        this._bottomHeight = this.getBottomHeight();

        if (step.currentElement === ELEMENT_BODY) {
            const backdropBody = this.getRenderer().createElement('div');
            this.getRenderer().addClass(backdropBody, 'joyride-backdrop');
            this.getRenderer().addClass(backdropBody, 'backdrop-body');
            this.getRenderer().setStyle(backdropBody, 'width', '100%');
            this.getRenderer().setStyle(backdropBody, 'height',   '100%');
            this.getRenderer().setStyle(backdropBody, 'flex-shrink', '0');
            this.getRenderer().setStyle(backdropBody, 'background-color', this.backDropBackgroundColor);
            this.getRenderer().appendChild(this.getContainerBackdrop(), backdropBody);

            return;
        }


        this.backdropTop = this.getRenderer().createElement('div');
        this.getRenderer().addClass(this.backdropTop, 'joyride-backdrop');
        this.getRenderer().addClass(this.backdropTop, 'backdrop-top');
        this.getRenderer().setStyle(this.backdropTop, 'width', '100%');
        this.getRenderer().setStyle(this.backdropTop, 'height', this._topHeight + 'px');
        this.getRenderer().setStyle(this.backdropTop, 'flex-shrink', '0');
        this.getRenderer().setStyle(this.backdropTop, 'background-color', this.backDropBackgroundColor);
        this.getRenderer().appendChild(this.getContainerBackdrop(), this.backdropTop);

        this.backdropLeft = this.getRenderer().createElement('div');
        this.getRenderer().addClass(this.backdropLeft, 'joyride-backdrop');
        this.getRenderer().addClass(this.backdropLeft, 'backdrop-left');
        this.getRenderer().setStyle(this.backdropLeft, 'left', '0');
        this.getRenderer().setStyle(this.backdropLeft, 'width', this._leftWidth + 'px');
        this.getRenderer().setStyle(this.backdropLeft, 'top', this._topHeight + 'px');
        this.getRenderer().setStyle(this.backdropLeft, 'bottom', this._bottomHeight + 'px');
        this.getRenderer().setStyle(this.backdropLeft, 'flex-shrink', '0');
        this.getRenderer().setStyle(this.backdropLeft, 'background-color', this.backDropBackgroundColor);
        this.getRenderer().appendChild(this.getContainerBackdrop(), this.backdropLeft);

        this.backdropRight = this.getRenderer().createElement('div');
        this.getRenderer().addClass(this.backdropRight, 'joyride-backdrop');
        this.getRenderer().addClass(this.backdropRight, 'backdrop-right');
        this.getRenderer().setStyle(this.backdropRight, 'left', this._rightWidth + 'px');
        this.getRenderer().setStyle(this.backdropRight, 'right', '0');
        this.getRenderer().setStyle(this.backdropRight, 'top', this._topHeight + 'px');
        this.getRenderer().setStyle(this.backdropRight, 'bottom', this._bottomHeight + 'px');
        this.getRenderer().setStyle(this.backdropRight, 'flex-shrink', '0');
        this.getRenderer().setStyle(this.backdropRight, 'background-color', this.backDropBackgroundColor);
        this.getRenderer().appendChild(this.getContainerBackdrop(), this.backdropRight);

        this.backdropBottom = this.getRenderer().createElement('div');
        this.getRenderer().addClass(this.backdropBottom, 'joyride-backdrop');
        this.getRenderer().addClass(this.backdropBottom, 'backdrop-bottom');
        this.getRenderer().setStyle(this.backdropBottom, 'left', '0');
        this.getRenderer().setStyle(this.backdropBottom, 'right', '0');
        this.getRenderer().setStyle(this.backdropBottom, 'bottom', '0');
        this.getRenderer().setStyle(this.backdropBottom, 'height', this._bottomHeight + 'px');
        this.getRenderer().setStyle(this.backdropBottom, 'flex-shrink', '0');
        this.getRenderer().setStyle(this.backdropBottom, 'background-color', this.backDropBackgroundColor);
        this.getRenderer().appendChild(this.getContainerBackdrop(), this.backdropBottom);


    }

    redraw(): void {
        this._topHeight = this.getTopHeight();
        this._leftWidth = this.getLeftWidth();
        this._rightWidth = this.getRightWidth();
        this._bottomHeight = this.getBottomHeight();

        if (this.backdropTop) {
            this.getRenderer().setStyle(this.backdropTop, 'height', this._topHeight + 'px');
            this.getRenderer().setStyle(this.backdropLeft, 'width', this._leftWidth + 'px');
            this.getRenderer().setStyle(this.backdropLeft, 'top', this._topHeight + 'px');
            this.getRenderer().setStyle(this.backdropLeft, 'bottom', this._bottomHeight + 'px');
            this.getRenderer().setStyle(this.backdropRight, 'left', this._rightWidth + 'px');
            this.getRenderer().setStyle(this.backdropRight, 'top', this._topHeight + 'px');
            this.getRenderer().setStyle(this.backdropRight, 'bottom', this._bottomHeight + 'px');
            this.getRenderer().setStyle(this.backdropBottom, 'height', this._bottomHeight + 'px');
        }
    }

    getTopHeight(): number {
        const el: HTMLElement = this.getCurrentElement().nativeElement;
        return el.getBoundingClientRect().top;
    }

    getLeftWidth(): number {
        const el: HTMLElement = this.getCurrentElement().nativeElement;
        return el.getBoundingClientRect().left;
    }

    getRightWidth(): number {
        const el: HTMLElement = this.getCurrentElement().nativeElement;
        return el.getBoundingClientRect().right;
    }

    getBottomHeight(): number {
        const containerHeight = this.getContainerBackdrop().getBoundingClientRect().height;
        const el: HTMLElement = this.getCurrentElement().nativeElement;
        const rect: DOMRect = el.getBoundingClientRect();
        const h = containerHeight - rect.top - rect.height;
        if (h < 0) { return 0; }
        return h;
    }


}
