<div class="faq-wrap" fxLayout="column wrap" fxLayoutAlign="center stretch" fxFlex="100%">

    <mat-accordion class="faqs-accordion" *ngFor="let d of data">
        <h3 class="font-size-24 text-center">
            <b>{{'ENUM.FAQ.'+d.key | translate}}</b>
        </h3><br>

        <mat-expansion-panel *ngFor="let f of d?.value?.content" >
            <mat-expansion-panel-header>
                <mat-panel-title class="font-size-18 font-weight-600" fxLayout="row" fxLayoutAlign="start center">
                    {{ f.question }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="font-size-14" [innerHTML]="f.answer">-</div>
        </mat-expansion-panel>

    </mat-accordion>

</div>