import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class PwdService {

    constructor(
        private http: HttpClient,
        private cookie: CookieService
    ) {
    }

    set origin(value: string) {
        if (this.origin == null || this.origin.length === 0 && value && value.length > 0) {
            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 30);
            this.cookie.set('origin', value, {expires, sameSite: 'Strict', path: '/'});
        }
    }

    get origin(): string | null {
        return this.cookie.get('origin');
    }

    clearOrigin(): void {
        this.cookie.delete('origin');
    }

    newAmbassadorAccount(data: any): Observable<any> {
        if (this.origin != null) {
            data.origin = this.origin;
        }
        const url = `${environment.basePath[environment.env].pwdService}/ambassador/new-account`;
        return this.http.post(url, data, {responseType: 'text'})
            .pipe(
                tap(() => this.clearOrigin())
            );
    }

    forgotPasswordAmbassador(email: string): Observable<any> {
        const url = `${environment.basePath[environment.env].pwdService}/ambassador/forgot-password`;
        return this.http.post(url, {email}, {responseType: 'text'});
    }

    resetPasswordAmbassador(data: {token: string, password: string}): Observable<any> {
        const url = `${environment.basePath[environment.env].pwdService}/ambassador/reset-password`;
        return this.http.post(url, data, {responseType: 'text'});
    }

    confirmationEmail(token: string): Observable<any> {
        const url = `${environment.basePath[environment.env].pwdService}/email-confirmation/validate-token`;
        return this.http.post(url, {token, userType: 'AMBASSADOR'}, {responseType: 'text'});
    }

}
