import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector     : 'faq-base',
    templateUrl  : './faq-base.component.html',
    styleUrls    : ['./faq-base.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FaqBaseComponent
{

}
