import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {exhaustMap, take, tap} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private _userService: UserService,
        private _router: Router
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this._userService.user$.pipe(
            take(1),
            exhaustMap(user => {

                let httpHeaders: HttpHeaders = req.headers;
                httpHeaders = httpHeaders.set('X-TENANT-ID', environment.scope);

                let customRequest = req.clone({
                    headers: httpHeaders
                });

                if (!user) {
                    return next.handle(customRequest);
                }

                if (req.url.indexOf('/oauth/token') > -1) {
                    return next.handle(customRequest);
                }

                httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + user.access_token);
                customRequest = req.clone({
                    headers: httpHeaders
                });

                return next.handle(customRequest).pipe(
                    tap(() => {
                    }, err => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status !== 401) {
                                return;
                            }
                            this._userService.logout();
                            this._router.navigate(['auth/login']);
                        }
                    })
                );

            })
        );
    }

}
