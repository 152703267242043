import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {PwdService} from '../../services/auth/pwd.service';
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    loading = false;
    success = false;
    forgotPasswordForm: FormGroup;

    accountNotExists = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {PwdService} _pwdService
     * @param _activatedRoute
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _pwdService: PwdService,
        private _activatedRoute: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this._pwdService.origin = this._activatedRoute.snapshot.queryParamMap.get('origin');
    }

    resetPassword(): void {
        if (this.forgotPasswordForm.valid) {
            this.loading = true;
            this._pwdService.forgotPasswordAmbassador(this.forgotPasswordForm.get('email').value).subscribe(resp => {
                this.loading = false;
                this.success = true;
                this.forgotPasswordForm.reset();
            }, (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 404 ) {
                        this.setAccountNotExists();
                    }
                }
                this.loading = false;
                this.success = false;
            });
        }
    }

    setAccountNotExists(): void {
        this.accountNotExists = true;
        setTimeout(() => this.accountNotExists = false, 5000);
    }

}
