import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ListModel} from '../../../@fuse/models/list.model';

@Injectable({
    providedIn: 'root'
})
export class FaqService {

    constructor(
        private http: HttpClient
    ) {
    }

    getList(type: string = 'AMBASSADOR_GENERAL'): Observable<ListModel<{answer: string, id: number, question: string, type: string}>> {
        const url = `${environment.basePath[environment.env].communicationService}/faq?type=${type}&size=1000`;
        return this.http.get<any>(url);
    }

}

export enum FaqEnum {
    AMBASSADOR_ACTIVITY = 'AMBASSADOR_ACTIVITY',
    AMBASSADOR_CONTRACT = 'AMBASSADOR_CONTRACT',
    AMBASSADOR_PAYMENT = 'AMBASSADOR_PAYMENT',
    AMBASSADOR_REGISTRATION = 'AMBASSADOR_REGISTRATION',
    AMBASSADOR_GENERAL= 'AMBASSADOR_GENERAL',
    AMBASSADOR_PUBLIC = 'AMBASSADOR_PUBLIC'
}

export declare type FAQ_CATEGORIES = 'ALL' | 'AMBASSADOR_GENERAL' | 'AMBASSADOR_REGISTRATION' | 'AMBASSADOR_CONTRACT' | 'AMBASSADOR_ACTIVITY' | 'AMBASSADOR_PAYMENT' | 'AMBASSADOR_PUBLIC';
