import * as FileSaver from 'file-saver';
import {DateUtilities} from './date-utilities.model';
import {FormControl} from '@angular/forms';

export abstract class Utilities {

    static isVoid(value: any): boolean {
        return value === null || value === undefined;
    }

    static inject(source, dest): any {
        if (source) {
            Object.keys(dest).forEach((v) => {
                if (dest[v] instanceof Object && source && source[v]) {
                    dest[v] = Utilities.inject(source[v], dest[v]);
                } else if (source && source[v]) {
                    dest[v] = source[v];
                }
            });
        }
        return dest;
    }

    static forceInject(source, dest): any {
        if (source && dest) {
            Object.keys(source).forEach((v) => {
                dest[v] = source[v];
            });
        }
    }

    static getQuery(obj: any): string {
        let haveAlreadyParams = false;
        let query = '?';
        Object.keys(obj).map((key) => {
            const value: any = obj[key];
            if (!Utilities.isVoid(value) && value.toString().length > 0) {
                if (haveAlreadyParams) {
                    query += '&';
                }
                query += key + '=' + value;
                haveAlreadyParams = true;
            }
        });
        if (!haveAlreadyParams) {
            return '';
        }

        return query;
    }

    static downloadBlobWithType(fileBlob: any, fileName: string, fileType: any = null): void {

        let extension: string;
        let mimeType: string;

        // extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
        extension = fileName.toLowerCase().split('.').pop();
        switch (extension) {
            case 'jpeg':
            case 'jpg':
                mimeType = 'image/jpeg';
                break;
            case 'png':
                mimeType = 'image/png';
                break;
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'doc':
                mimeType = 'application/msword';
                break;
            case 'xlsx':
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case 'zip':
                mimeType = 'application/zip';
                break;
            case 'svg':
                mimeType = 'image/svg+xml';
                break;
            default:
                mimeType = fileType;  // se non trova l'estensione usa il tipo del file
        }

        const blob: Blob = new Blob([fileBlob], {type: mimeType});
        FileSaver.saveAs(blob, fileName);
    }

    static cleanStrToUrl(str: string): string {
        if (str === null || str === undefined) {
            return str;
        }
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s/g, '')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase()
            ;
    }

    static compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    static compareDate(a: Date, b: Date, isAsc: boolean): number {
        const isBefore = DateUtilities.compareDateIsBefore(a, b);
        if (isAsc) {
            return isBefore ? -1 : 1;
        }
        return isBefore ? 1 : -1;
    }

    static validateEmail(email: string): boolean {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        return email && emailPattern.test(email);
    }

    static noWhitespaceValidator(control: FormControl): any | null {
        let isWhitespace = true;
        if (control && control.value) {
            isWhitespace = (control.value).trim().length === 0;
        }
        const isValid = !isWhitespace;
        return isValid ? null : {'whitespace': true};
    }

    static copyToClipboard(text: string): void {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    }

}
