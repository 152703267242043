<div id="faq" class="page-layout simple full-width">

    <div class="header accent p-24 h-52" fxLayout="column" fxLayout.gt-xs="row"
         fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center">

        <div fxLayout="column" fxLayoutAlign="center start">

            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Supporto</span>
            </div>

        </div>

    </div>

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" class="fast-info" fxLayoutAlign="start start" ngClass.gt-sm="pr-56"
             fxLayoutGap.gt-xs="10px">

            <div fxLayout="column" fxLayoutAlign="start start">


                <div fxLayout="column" class="w-100-p">
                    <p>Per assistenza scrivici all'indirizzo e-mail</p>
                    <a href="mailto:supportoamministrativo@salesmart.com" class="p-12 bt bb br bl" ngClass.xs="mb-8" fxLayout="row"
                       fxLayoutAlign="center center">
                        <mat-icon>mail</mat-icon>&ensp;ambassador@salesmart.it</a>
                </div>
            </div>

            <div app-faq-list></div>
        </div>
        <!-- / CONTENT -->

    </div>
</div>