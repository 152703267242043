import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad, Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {PwdService} from './pwd.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

    private alreadyCheck = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private pwdService: PwdService
    ) {
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(null, null);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService.user$.pipe(
            take(1),
            switchMap(a => {
                if (!this.alreadyCheck) {
                    this.alreadyCheck = true;
                    return this.userService.checkLogged();
                }
                return of(a);
            }),
            map(user => {
                const isAuth = !!user;
                if (isAuth) {
                    return true;
                }
                this.pwdService.origin = route?.queryParamMap?.get('origin') ?? null;
                return this.router.createUrlTree(['/auth/login']);
            })
        );
    }

}
