<div class="text-justify">
    <h2>Descrizione del Servizio</h2>
    <ol>
        <li>
            La società Salesmart Srl, con P.IVA 15777811009 e sede in Viale Città D'Europa 681, 00144 Roma (di
            seguito denominata "Salesmart"), tramite una piattaforma online (di seguito "Piattaforma") offre un
            "Servizio" agli utenti registrati (di seguito "Ambassador" o "Utente") per la gestione di suggerimenti
            di acquisto per prodotti e servizi offerti dai "Partner Commerciali". Gli Ambasador possono suggerire ad
            amici, parenti, conoscenti o terze parti (i "Clienti") i prodotti e servizi dei Partner Commerciali. Se,
            in seguito ai predetti suggerimenti, i Clienti acquistano beni o servizi dai Partner Commerciali, gli
            Ambassador ricevono da Salesmart una ricompensa prestabilita, ai sensi del contratto da procacciatore di
            affari che l'Ambassador dovrà sottoscrivere una volta registrato alla piattaforma.
        </li>
        <li>
            L'utilizzo del Servizio è esclusivamente riservato alle persone fisiche che abbiano compiuto il
            diciottesimo (18) anno di età.
        </li>
        <li>
            Accettando i presenti termini e condizioni, ciascun Utente si impegna a non utilizzare il Servizio per
            finalità commerciali. Sussistono finalità commerciali quando il suggerimento dei prodotti e servizi dei
            Partner Commerciali è effettuato regolarmente e in grandi volumi, con l'intenzione di ricavarne
            stabilmente un profitto.
        </li>
        <li>
            Qualora l'Utente intenda, al momento della registrazione o successivamente, utilizzare il Servizio per
            finalità commerciali, è obbligato a informare Salesmart, immediatamente e per iscritto di tale sua
            intenzione. In tal caso, Salesmart si riserva il diritto di escludere l'Utente dal Servizio oppure di
            autorizzare l'Utente ad utilizzare il Servizio per finalità commerciali, sulla base di una valutazione
            effettuata caso per caso. L'utilizzo del Servizio per finalità commerciali senza autorizzazione può
            essere soggetto a restrizioni e può esporre l'Utente alle sanzioni previste dalle normative applicabili.
        </li>
        <li>
            Nell'utilizzo del Servizio, l'Utente opera autonomamente e non è soggetto ad alcuna indicazione o
            direttiva da parte di Salesmart. In particolare, ciascun Ambassador sceglie liberamente i destinatari a
            cui inviare i propri suggerimenti. Ciascun Ambassador è esclusivamente responsabile di adempiere alle
            normative, anche fiscali, che lo riguardano.
        </li>
    </ol>
    <p>L'utilizzo del Servizio è gratuito per l'Utente.</p>
    <h2>Registrazione e sottoscrizione del contratto da procacciatore d'affari</h2>
    <ol>
        <li>
            Per poter utilizzare il Servizio, l'Utente deve registrarsi al portale e creare un proprio profilo.
            L'Utente deve compilare il modulo di registrazione e accettare i presenti termini e condizioni di
            utilizzo. L'Utente deve inoltre prendere atto della privacy policy cui si fa riferimento durante la
            registrazione.
        </li>
        <li>
            Al completamento della registrazione, al primo accesso, l'Utente deve completare il proprio profilo con
            i dati richiesti dalla piattaforma e sottoscrivere digitalmente il contratto da procacciatore d'affari
            che verrà generato in automatico dalla piattaforma. Nessuna attività di suggerimento è consentita
            all'Utente prima di aver sottoscritto digitalmente il contratto da procacciatore d'affari.
        </li>
        <li>
            Ciascun Utente è tenuto, in fase di registrazione ed in fase di completamento del profilo, a fornire
            informazioni veritiere e a controllare in fase di firma del contratto la correttezza dei dati inseriti.
        </li>
    </ol>
    <h2>Diritto di recesso</h2>
    <ol>
        <li>
            L'Utente ha il diritto di recedere dal contratto con Salesmart in qualunque momento, senza dover fornire
            una motivazione al riguardo. Per esercitare il diritto di revoca e/o di recesso, l'Utente deve inviare
            una comunicazione tramite e-mail all'indirizzo <b>salesmartsrl@legalmail.it</b>. L'utente può usare allo
            scopo
            il formulario di recesso presente in piattaforma, che dovrà opportunamente compilare ed inviare.
        </li>
    </ol>
    <h2>Modalità di condivisione dei suggerimenti</h2>
    <ol>
        <li>
            L'Ambassador può condividere le offerte dei Partner Commerciali tramite apposito QRCode e/o Link che
            trova all'interno della piattaforma. Ogni QRCode e/o Link fa riferimento ad una specifica offerta di uno
            dei Partner Commerciali.
        </li>
        <li>
            Il QRCode ed il Link rimandano il potenziale Cliente ad una pagina web (pagina di ricontatto) dove
            l'interessato può lasciare i propri dati ed essere ricontattato direttamente dal Partner Commerciale
            sponsor di quell'offerta.
        </li>
        <li>
            L'Ambassador tramite smartphone e/o tramite stampa può mostrare il QRcode al potenziale Cliente
            interessato, che scansionando lo stesso, verrà ridiretto sulla pagina di ricontatto per quell'offerta.
        </li>
        <li>
            L'Ambassador, all'interno della piattaforma troverà dei materiali di comunicazione (banner, brochure e
            altro) ufficiali dei Partner Commerciale che potrà utilizzare per realizzare delle promozioni sulle
            proprie pagine social o sul proprio sito/blog.
        </li>
        <li>
            L'Ambassador agirà in qualità di autonomo titolare del trattamento, potrà contattare unicamente soggetti
            interessati che il medesimo Ambassador può legittimamente contattare sulla base del consenso dallo
            stesso acquisito al fine di promuovere i servizi del Partner Commerciale.
        </li>
        <li>
            È fatto espresso divieto all'Ambassador di condividere con Salesmart e/o con il Partner Commerciale in
            qualunque modo ed attraverso qualunque canale e/o mezzo i dati personali dei soggetti contattati o che
            si intende contattare. Parimenti, Salesmart e/o il Partner Commerciale non forniranno all’Ambassador
            alcuna istruzione in merito alle categorie di soggetti da contattare.
        </li>
    </ol>
    <h2>Calcolo degli importi di remunerazione</h2>
    <ol>
        <li>
            L’Ambassador riceverà una remunerazione per i suggerimenti che andranno a buon fine, ovvero per i soli
            suggerimenti che hanno portato il Cliente ad aderire al servizio secondo quanto riportato nel contratto
            da Procacciatore che verrà siglato digitalmente a seguito della registrazione in piattaforma.
        </li>
        <li>
            L'importo di remunerazione per ogni suggerimento andato a buon fine è stabilito all'interno del
            Contratto da Procacciatore che l'Ambassador sigla digitalmente a seguito della registrazione in
            piattaforma.
        </li>
        <li>
            E' sempre possibile per l'Ambassador monitorare l'andamento dei propri ricavi all'interno della apposita
            sezione della piattaforma.
        </li>
        <li>
            Al termine di ogni mese la piattaforma rende possibile all'Ambassador richiedere il pagamento dei
            suggerimenti andati a buon per quel periodo, l'Ambassador può richiedere il pagamento sia per un singolo
            mese sia per più mesi trascorsi.
        </li>
        <li>
            Quando l'Ambassador fa richiesta di pagamento è necessario che lo stesso firmi digitalmente la ricevuta
            di prestazione occasionale precompilata dalla piattaforma.
        </li>
        <li>
            La ricevuta di prestazione occasionale deve essere verificata nei dati anagrafici e nella veridicità
            delle dichiarazioni proposte all'Ambassador. Salesmart declina ogni responsabilità per la non
            correttezza delle informazioni riportate nella dichiarazione.
        </li>
        <li>
            Gli importi relativi alle remunerazioni si intendono al lordo delle ritenute di acconto obbligo di legge
            e che verranno versate da Salesmart per conto dell'Ambassador. Per i compensi erogati Salesmart fornirà
            all'Ambassador relativa certificazione unica dei versamenti effettuati.
        </li>
        <li>
            Le ricompense maturate dall'Ambassador possono essere soggette a ulteriori imposizioni fiscali e
            comportare obblighi di comunicazione nei confronti delle autorità preposte. L'Utente è esclusivamente
            responsabile di adempiere tali obblighi di comunicazione e imposizioni, ove esistenti.
        </li>
    </ol>
    <h2>Obblighi dell'utente</h2>
    <ol>
        <li>
            L'Utente può utilizzare il Servizio solo nel rispetto delle leggi applicabili e dei presenti termini e
            condizioni. In particolare l'Utente si impegna a non utilizzare il Servizio per pubblicizzare sé stesso
            o i propri prodotti, per fare offerte commerciali o nel contesto di contenuti illegali o contrari alla
            pubblica moralità.
        </li>
        <li>
            L'Utente, nell'utilizzare il Servizio, può inviare suggerimenti tramite e-mail, chat o altrimenti
            soltanto ad amici, persone o imprese che abbiano espresso il proprio consenso a ricevere i predetti
            suggerimenti dall‘Utente. Suggerimenti non richiesti e inviati senza che il destinatario abbia
            manifestato il proprio consenso a riceverli sono soggetti a limitazioni e, a seconda delle circostanze,
            potrebbero esporre l'Utente a responsabilità e sanzioni ai sensi delle applicabili disposizioni
            normative.
        </li>
        <li>
            L'Utente non può cambiare in alcun modo i contenuti delle offerte dei Partner Commerciali. L'Utente,
            esclusivamente nel contesto del messaggio personale inviato al destinatario del suggerimento, può
            fornire al destinatario medesimo informazioni aggiuntive, purché siano vere, appropriate e non atte a
            confondere.
        </li>
        <li>
            L'Utente è tenuto a non nascondere – e a non fornire informazioni svianti circa - il fatto che, per ogni
            suggerimento andato a buon fine, otterrà una remunerazione.
        </li>
        <li>
            All'Utente è proibito l'utilizzo di marchi o di altri diritti dei Partner Commerciali senza la
            preventiva autorizzazione di Salesmart o, a seconda dei casi, dei Partner Commerciali stessi.
        </li>
        <li>
            È vietato per l'Utente pubblicare promozioni generate dalla piattaforma su siti di cui l'Utente non
            detiene tutti i diritti per la pubblicazione.
        </li>
        <li>
            E' vietato intraprendere qualunque azione che sia assimilabile ad un'attività di Spam.
        </li>
        <li>
            L'Utente si impegna a conservare con cura i propri dati d'accesso e la password del proprio profilo e a
            proteggere la propria password da terzi non autorizzati. Tutte le azioni compiute dopo che sia stato
            effettuato l'accesso al profilo dell'Utente con la sua password si considerano compiute dall'Utente
            medesimo.
        </li>
        <li>
            In caso di traffico fraudolento o di comprovata attività di Spam, Salesmart si riserva il diritto alla
            sospensione dei pagamenti per 180 (centoottanta) giorni durante i quali verranno effettuate le analisi
            del caso per verificare o meno il comportamento contrario al presente regolamento. In caso di traffico
            fraudolento o di Spam la società si riserva il diritto di interrompere la collaborazione con effetto
            immediato e di annullare ogni pagamento in essere ovvero ricalcolare, in dipendenza delle analisi
            effettuate, il corretto importo da corrispondere all'affiliato.
        </li>
    </ol>
    <h2>Condizioni di pagamento</h2>
    <ol>
        <li>
            Il pagamento delle ricompense avviene esclusivamente, senza costi per l'Utente, tramite bonifici SEPA
            (single euro payments area) in Euro o nella diversa valuta del paese dell'area SEPA in cui si trova la
            banca ricevente. Per il bonifico di ricompense in valute diverse dall'Euro o dalla diversa valuta del
            paese dell'area SEPA in cui si trova la banca ricevente, l'Utente potrebbe ricevere l'addebito di
            commissioni o spese da parte della propria banca.
        </li>
        <li>
            Il bonifico della ricompensa sul conto corrente indicato dall'Utente – anche qualora non fosse intestato
            all'Utente – libera definitivamente e irrevocabilmente Salesmart da ogni pretesa dell'Utente relativa al
            pagamento delle ricompense.
        </li>
        <li>
            Eventuali obiezioni dell'Utente in merito al saldo o ad altri dettagli del proprio conto potranno essere
            sollevate dall'Utente al più tardi entro sei (6) settimane dalla ricezione dell'e-mail con cui Salesmart
            informa l'Utente dell'importo delle ricompense maturate. Tali obiezioni potranno essere sollevate
            esclusivamente per via e-mail all'indirizzo fornito in piattaforma. L'importo delle ricompense maturate
            s'intenderà definitivamente approvato qualora, entro il predetto termine, l'Utente non sollevi obiezioni
            secondo le modalità indicate.
        </li>
        <li>
            Le ricompense possono essere cancellate o stornate da Salesmart qualora si presentino attività
            considerate in violazione dei presenti termini e condizioni da arte dell'Utente
        </li>
        <li>
            La responsabilità di effettuare le dichiarazioni e le trattenute fiscali richieste dalle applicabili
            disposizioni in relazione al Servizio è esclusivamente dell'Utente. Salesmart ha il diritto, ove ne sia
            obbligata ai sensi delle disposizioni normative applicabili, a fornire alle autorità informazioni sui
            conti degli Utenti e sulle operazioni che siano state effettuate sugli stessi.
        </li>
        <li>
            Sulle ricompense non maturano interessi.
        </li>
    </ol>
    <h2>Responsabilità</h2>
    <ol>
        <li>
            Salvo che sia diversamente stabilito nei presenti termini e condizioni (incluse le seguenti previsioni),
            Salesmart è soggetta a responsabilità contrattuale ed extracontrattuale ai sensi delle applicabili
            disposizioni di legge.
        </li>
        <li>
            Salesmart non assume alcuna responsabilità per i link che siano utilizzati o modificati dall'Utente in
            violazione dei presenti termini e condizioni, in particolar modo in caso di violazione di diritti di
            terzi.
        </li>
        <li>
            Salesmart non assume alcuna responsabilità per la disponibilità dei prodotti o servizi offerti dai
            Partner Commerciali.
        </li>
        <li>
            Salesmart non assume alcuna responsabilità per l'affidabilità e l'adempimento dei Partner Commerciali o
            per le qualità dei prodotti o servizi dai medesimi offerti.
        </li>
        <li>
            Salesmart non assume alcuna responsabilità per i suggerimenti inviati dall'Utente.
        </li>
        <li>
            Salesmart non assume alcuna responsabilità in materia fiscale e di imposta per i singoli Utenti.
        </li>
    </ol>
    <h2>Protezione dei dati, diritto d'autore</h2>
    <ol>
        <li>
            L'Utente è invitato ad esaminare la privacy policy di Salesmart, che disciplina la protezione dei dati
            personali dell'Utente.
        </li>
        <li>
            Tutti i diritti relativi alla piattaforma ed ai suoi contenuti, nonché ai siti web dei Partner
            Commerciali e ai loro contenuti, i domini internet, la tecnologia utilizzata per il Servizio e i marchi
            e i loghi di Salesmart e dei Partner Commerciali, così come i diritti di autore, i diritti relativi alle
            banche dati e i diritti al nome, i disegni, i modelli di utilità e i brevetti, e tutte le licenzead
            utilizzare i predetti diritti presenti e futuri, già concessi o che saranno concessi in futuro sono di
            seguito riferiti come "Diritti". Salesmart (o, a seconda dei casi, i Partner Commerciali con riferimento
            ai siti internet dai medesimi operati, ivi inclusi i contenuti dei medesimi e il loro logo) è
            proprietaria esclusiva dei propri Diritti. I presenti termini e condizioni non comportano alcun
            trasferimento o concessione all'Utente dei Diritti. Salesmart concede all'Utente una licenza personale,
            limitata, non esclusiva, revocabile e non trasferibile all'uso del Servizio ai sensi dei presenti
            termini e condizioni. Il predetto diritto d'uso termina con la cessazione del contratto con Salesmart.
            L'Utente s'impegna a non riprodurre, copiare, imitare, modificare, decompilare, sfruttare o utilizzare
            qualsiasi contenuto o tecnologia protetta dai Diritti, nonché a non renderla disponibile a terze parti
            senza il preventivo consenso scritto di Salesmart e/o, a seconda dei casi, dei Partner Commerciali, a
            meno che tale utilizzo sia consentito dai presenti termini e condizioni e sia coerente con le finalità
            proprie del Servizio.
        </li>
    </ol>
    <h2>Cessazione</h2>
    <ol>
        <li>
            Sia l'Utente che Salesmart possono dare disdetta al contratto con preavviso di 3 giorni. Resta fermo il
            diritto di recesso per giusta causa senza preavviso.
        </li>
        <li>
            La disdetta, il recesso e la risoluzione sono comunicati per e-mail all'indirizzo fornito in
            piattaforma.
        </li>
        <li>
            Con la cessazione del contratto, Salesmart disattiva ed elimina il profilo dell'Utente e blocca
            l'accesso dell'Utente al Servizio.
        </li>
    </ol>
</div>