<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img *ngIf="avatar === null" class="logo-icon" src="assets/images/logos/fuse.svg">
                    <app-avatar-showing [avatar]="avatar" width="40px" height="40px"></app-avatar-showing>
                    <!--<div *ngIf="avatar !== null" [innerHTML]="avatar" class="avatar-profile-logo40"></div>-->
                </div>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="avatar === null" class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg">
                    <app-avatar-showing [avatar]="avatar" width="40px" height="40px" class="mr-0 mr-sm-16"></app-avatar-showing>
                    <!--<div *ngIf="avatar !== null" [innerHTML]="avatar" class="avatar-profile-logo40 mr-0 mr-sm-16"></div>-->
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user?.firstName}} {{user?.lastName}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <a routerLink="/pages/profile" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Profilo</span>
                </a>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <button mat-icon-button
                    matTooltip="Visualizza tutorial"
                    class="quick-panel-toggle-button"
                    (click)="activeDemo()">
                <mat-icon class="secondary-text">
                    video_settings
                </mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
