import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {InfoDialogService} from '@fuse/components/info-dialog/info-dialog.service';
import {CookieService} from "ngx-cookie-service";

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

    isVisible = false;

    @ViewChild('cookieRef')
    cookieTemplate: TemplateRef<any>;

    private cookieKey = 'cookie-policy';

    constructor(
        private _infoDialogService: InfoDialogService,
        private cookieService: CookieService
    ) {
    }

    ngOnInit(): void {
        this.check();
    }

    private check(): void {
        if (!this.checkExists()) {
            this.isVisible = true;
        } else {
            this.isVisible = false;
        }
    }

    openCookie(): void {
        this._infoDialogService.showInfoDialogTemplate({
            template: this.cookieTemplate,
            width: '600px',
            okLabel: 'Continua',
            titleLabel: 'Cookie Policy'
        });
    }

    checkExists(): boolean {
        return this.cookieService.check(this.cookieKey);
    }

    addCookie(): void {
        this.cookieService.set(this.cookieKey, '1', {expires: new Date(2100, 1), path: '/'});
        this.check();
    }

}
