import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';

declare let gtag: (...args) => void;

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    constructor(
        private readonly router: Router
    ) {
    }

    private isInitialized = false;

    private isProduction(): boolean {
        return environment.env === 'prod';
    }

    private googleAnalyticsPageView(url: string): void {
        if (typeof gtag !== 'undefined') {
            gtag('event', 'page_view', {
                page_path: url
            });
        }
    }

    private trackPageView(): void {
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.googleAnalyticsPageView(e.urlAfterRedirects);
            }
        });
    }

    public init(): void {
        if (this.isProduction() && !this.isInitialized) {
            this.isInitialized = true;
            this.trackPageView();
        }
    }

}
