import {InMemoryDbService} from 'angular-in-memory-web-api';
import {Observable} from 'rxjs';
import {DemoWizardService} from '../main/apps/demo-wizard/services/demo-wizard.service';
import {map} from 'rxjs/operators';
import {Commissioning} from './demo/commissioning';
import {LandingDeployed} from './demo/landing-deployed';
import {Qrcode} from './demo/qrcode';
import {CommissioningProspectByLeadorigins} from './demo/commissioning-prospect-by-leadorigins';
import {Leadorigin, ListObject} from './demo/leadorigin';
import {Avatar} from './demo/avatar';
import {Wallet} from './demo/wallet';
import {Injectable} from '@angular/core';
import {Performance} from './demo/dashboard';

@Injectable({
    providedIn: 'root'
})
export class FakeDbService implements InMemoryDbService {
    constructor(
        private demoWizardService: DemoWizardService
    ) {
    }

    createDb(): Observable<{}> {
        return this.demoWizardService.isDemoSubject$.pipe(
            map(it => {
                if (it) {
                    return {

                        // DEMO
                        'commissioning/list-commissionings-for-brands': Commissioning,
                        'landing/deployed': LandingDeployed,
                        'landing/generateUrlUserFriendly': 'https://www.google.com',
                        'barcodes/zxing/qrcode': Qrcode,
                        'commissioning/prospect-by-leadorigins': CommissioningProspectByLeadorigins,
                        'performance/ambassador': Performance,
                        'leadorigin/list-object': ListObject,
                        'leadorigin/list': Leadorigin,
                        'avatar': Avatar,
                        'wallet': Wallet,

                    };
                } else {
                    return {};
                }
            })
        );
    }
}
