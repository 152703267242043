import {ConnectedPosition, ConnectionPositionPair} from '@angular/cdk/overlay';

export const DEMO_WIZARD_INJECTOR = {};
export const ELEMENT_BODY = 'body-theme';

export interface IStepInterface {

    step: number;
    currentElement: string;
    currentTitle: string;
    currentText: string;
    page: string;
    originPosition: ConnectedPosition;
    overlayPosition?: ConnectedPosition;
    handlerBeforeView?: (props: any) => void;

}

export const allDataStep: IStepInterface[] = [
    {
        step: 1,
        currentElement: ELEMENT_BODY,
        currentTitle: 'BENVENUTO/A NELLA PIATTAFORMA AMBASSADOR LINKEM!',
        currentText: `Per ogni amico che diventa cliente, guadagni da subito una provvigione sul tuo conto corrente!<br>
        Completa il tuo profilo ed inizia ad usufruire dei numerosi vantaggi!<br>
        <p>Per rivedere questa guida, clicca "Visualizza tutorial" nel menu in alto a destra.</p>`,
        page: 'pages/dashboard',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },
    {
        step: 2,
        currentElement: 'demo-wizard-default-offer',
        currentTitle: 'OFFERTE',
        currentText: `Qui trovi le offerte da condividere con i tuoi contatti, tramite QR-CODE o LINK!`,
        page: 'pages/offers',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'center'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'bottom'}
        )
    },
    /*{
        step: 3,
        currentElement: 'demo-wizard-default-offer-qrcode',
        currentTitle: 'Qr-code',
        currentText: `Il QR-CODE identifica il tuo account.
        Condividilo sempre con i tuoi contatti per ricevere compensi.`,
        page: 'pages/offers',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'bottom'}
        ),
        handlerBeforeView: async (props) => {
            // return new Promise(resolve => setTimeout(() => resolve(), 600));
        }
    },*/
    {
        step: 3,
        currentElement: 'dashboard-demo-wizard',
        currentTitle: 'RISULTATI',
        currentText: `Da qui segui l'avanzamento delle tue segnalazioni: quando vengono confermate come contratti attivati, richiedi il pagamento!`,
        page: 'pages/dashboard',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'center'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },
    {
        step: 4,
        currentElement: 'demo-wizard-intro-personal-results',
        currentTitle: 'RIEPILOGO ATTIVITÀ',
        currentText: `Da qui puoi vedere i contratti che hai stipulato, l'ammontare dei guadagni, e puoi richiedere i tuoi pagamenti!`,
        page: 'pages/personal-details',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'center'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },
    {
        step: 5,
        currentElement: ELEMENT_BODY,
        currentTitle: 'SUPPORTO',
        currentText: `
           Qui trovi le risposte alle domande più frequenti, oltre a email e telefono dell'assistenza.<br />
           Contattaci per ogni necessità!
        `,
        page: 'pages/knowledge-base',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'top'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },
    {
        step: 6,
        currentElement: 'demo-wizard-profile-about',
        currentTitle: 'Completa il tuo profilo',
        currentText: `
            Per attivare il tuo account Ambassador e promuovere le offerte Linkem a tutti i tuoi amici è sufficiente:<br>
            <ul>
                <li>Compilare la sezione Profilo con le tue Informazioni Personali</li>
                <li>Indicare le coordinate bancarie dove vuoi ricevere i pagamenti</li>
                <li>Generare e firmare online il contratto digitale che riceverai via email</li>
            </ul>
        `,
        page: 'pages/profile',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'top'},
            {overlayX: 'center', overlayY: 'top'}
        ),
        overlayPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },

];

export const allStepDataMobile: IStepInterface[] = [
    {
        step: 1,
        currentElement: ELEMENT_BODY,
        currentTitle: 'BENVENUTO/A NELLA PIATTAFORMA AMBASSADOR LINKEM!',
        currentText: `Per ogni amico che diventa cliente, guadagni da subito una provvigione sul tuo conto corrente!<br />
                    Completa il tuo profilo ed inizia ad usufruire dei numerosi vantaggi! <br/>
                    Per rivedere questa guida, clicca "Visualizza tutorial" nel menu in alto a destra.`,
        page: 'pages/dashboard',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        )
    },
    {
        step: 2,
        currentElement: 'mn_dashboards',
        currentTitle: 'RISULTATI',
        currentText: `Da qui segui l'avanzamento delle tue segnalazioni: quando vengono confermate come contratti attivati, richiedi il pagamento!`,
        page: 'pages/dashboard',
        originPosition: new ConnectionPositionPair(
            {originX: 'end', originY: 'top'},
            {overlayX: 'start', overlayY: 'bottom'}
        ),
        handlerBeforeView: async (props) => {
            if (props.sidebarService) {
                if (!props.sidebarService.getSidebar('navbar').opened) {
                    await new Promise((resolve => setTimeout(() => resolve(true), 200)));
                    await props.sidebarService.getSidebar('navbar').toggleOpen();
                    return new Promise((resolve => setTimeout(() => resolve(), 200)));
                }
            }
        }
    },
    {
        step: 3,
        currentElement: 'mn_offers',
        currentTitle: 'OFFERTE',
        currentText: `Qui trovi le offerte da condividere con i tuoi contatti tramite QR-CODE!`,
        page: 'pages/offers',
        originPosition: new ConnectionPositionPair(
            {originX: 'end', originY: 'top'},
            {overlayX: 'start', overlayY: 'bottom'}
        ),
        handlerBeforeView: async (props) => {
            if (props.sidebarService) {
                if (!props.sidebarService.getSidebar('navbar').opened) {
                    await new Promise((resolve => setTimeout(() => resolve(true), 100)));
                    await props.sidebarService.getSidebar('navbar').toggleOpen();
                    return new Promise((resolve => setTimeout(() => resolve(), 100)));
                }
            }
        }
    },
    {
        step: 4,
        currentElement: 'mn_risultati',
        currentTitle: 'RIEPILOGO ATTIVITÀ',
        currentText: `Da qui puoi vedere i contratti che hai stipulato, l'ammontare dei guadagni, e puoi richiedere i tuoi pagamenti!`,
        page: 'pages/personal-details',
        originPosition: new ConnectionPositionPair(
            {originX: 'end', originY: 'top'},
            {overlayX: 'start', overlayY: 'bottom'}
        ),
        handlerBeforeView: async (props) => {
            if (props.sidebarService) {
                if (!props.sidebarService.getSidebar('navbar').opened) {
                    await new Promise((resolve => setTimeout(() => resolve(true), 100)));
                    await props.sidebarService.getSidebar('navbar').toggleOpen();
                    return new Promise((resolve => setTimeout(() => resolve(), 100)));
                }
            }
        }
    },
    {
        step: 5,
        currentElement: 'mn_knowledge-base',
        currentTitle: 'SUPPORTO',
        currentText: `Qui trovi le risposte alle domande più frequenti, oltre a email e telefono dell'assistenza. <br />
                        Contattaci per ogni necessità!`,
        page: 'pages/knowledge-base',
        originPosition: new ConnectionPositionPair(
            {originX: 'end', originY: 'top'},
            {overlayX: 'start', overlayY: 'bottom'}
        ),
        handlerBeforeView: async (props) => {
            if (props.sidebarService) {
                if (!props.sidebarService.getSidebar('navbar').opened) {
                    await new Promise((resolve => setTimeout(() => resolve(true), 100)));
                    await props.sidebarService.getSidebar('navbar').toggleOpen();
                    return new Promise((resolve => setTimeout(() => resolve(), 100)));
                }
            }
        }
    },
    {
        step: 6,
        currentElement: ELEMENT_BODY,
        currentTitle: 'Completa il tuo profilo',
        currentText: `
            Per attivare il tuo account Ambassador e promuovere le offerte e i servizi delle aziende partner, è sufficiente:<br>
            <ul>
                <li>Compilare la sezione Profilo con le tue Informazioni Personali</li>
                <li>Indicare le coordinate bancarie dove vuoi ricevere i pagamenti</li>
                <li>Generare e firmare online il contratto digitale che riceverai via email</li>
            </ul>
        `,
        page: 'pages/profile',
        originPosition: new ConnectionPositionPair(
            {originX: 'center', originY: 'center'},
            {overlayX: 'center', overlayY: 'center'}
        ),
        handlerBeforeView: async (props) => {
            if (props.sidebarService) {
                if (props.sidebarService.getSidebar('navbar').opened) {
                    await new Promise((resolve => setTimeout(() => resolve(true), 100)));
                    await props.sidebarService.getSidebar('navbar').close(true);
                    return new Promise((resolve => setTimeout(() => resolve(), 100)));
                }
            }
        }
    },
];
