import {Component, ElementRef, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DEMO_WIZARD_INJECTOR, IStepInterface} from '../services/step.interface';
import {DemoWizardService} from '../services/demo-wizard.service';


@Component({
    templateUrl: './layer.component.html',
    selector: 'app-layer-web-demo-wizard',
    styleUrls: ['./layer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export default class LayerComponent {

    constructor(
        @Inject(DEMO_WIZARD_INJECTOR) private data: any
    ) {
    }

    getService(): DemoWizardService {
        return this.data.service;
    }

    getCurrent(): IStepInterface {
        return this.data.step;
    }

    hasNext(): boolean {
        return this.getService().hasNext();
    }

    hasPrev(): boolean {
        return this.getService().hasPrevious();
    }

    next(): void {
        this.getService().nextDemoWizard();
    }

    prev(): void {
        this.getService().prevDemoWizard();
    }

    get title(): string {
        return this.getCurrent().currentTitle;
    }

    get content(): string {
        return this.getCurrent().currentText;
    }

    stopTour(): void {
        this.getService().stop();
    }

    isPending(): boolean {
        return this.getService().getIsPending();
    }

}
