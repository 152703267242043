<div class="become-ambassador-component-wrapper" fxLayout="column" fxLayoutAlign="start center">
    
    <div class="onda-wrap w-100-p" fxLayout="row" fxLayoutAlign="center center">
        <!-- <img src="/assets/images/backgrounds/onda-first-web.png" /> -->
    </div>

    <div class="cards-container text-center" fxLayout="column" fxLayout.gt-sm="row"
         fxLayoutAlign="center center"
         fxLayoutAlign.gt-sm="center start"
         fxLayoutGap="15px">

        <div class="card-wrap" fxLayout="column">
            <div class="icon-wrap w-100-p" fxLayout="row" fxLayoutAlign="center center">
                <img src="/assets/images/icons/register-icon.png" />
            </div>
            <h3 class="font-weight-400 fuse-white-500-fg m-0"
                ngClass.lg="font-size-33" ngClass.md="font-size-33" ngClass.xs="font-size-27"
                fxLayoutAlign="center center" ngClass.gt-sm="h-40" ngClass.lt-md="text-center w-100-p">
                REGISTRATI
            </h3>
            <p ngClass.lt-md="text-center m-0">
                <strong>Diventa Ambassador</strong>, <br>
                accedi alla piattaforma e <br>
                completa il tuo account.
            </p>
        </div>

        <div class="card-wrap" fxLayout="column">
            <div class="icon-wrap w-100-p" fxLayout="row" fxLayoutAlign="center center">
                <img src="/assets/images/icons/social-icon.png" />
            </div>
            <h3 class="font-weight-400 fuse-white-500-fg m-0" fxLayoutAlign="center center" ngClass.gt-sm="h-40" ngClass.lt-md="text-center w-100-p">
                CONDIVIDI
            </h3>
            <p ngClass.lt-md="text-center m-0">
               <strong>Riceverai un QR-Code</strong> <br>
               da condividere sui tuoi social e 
               mostrare agli amici per fargli 
               conoscere le nostre offerte.
            </p>
        </div>

        <div class="card-wrap" fxLayout="column">
            <div class="icon-wrap w-100-p" fxLayout="row" fxLayoutAlign="center center">
                <img src="/assets/images/icons/gift-icon.png" />
            </div>
            <h3 class="font-weight-400 fuse-white-500-fg m-0" fxLayoutAlign="center center" ngClass.gt-sm="h-40" ngClass.lt-md="text-center w-100-p">
                GUADAGNA
            </h3>
            <p ngClass.lt-md="text-center">
                <strong>per ogni acquisto </strong> <br>
                realizzato dai tuoi contatti
            </p>
        </div>

    </div>
    <div class="button-to-login" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button class="border-radius-8" aria-label="ISCRIVITI ORA" (click)="showUp()">
            ISCRIVITI ORA
        </button>
    </div>

</div>