import localeDeAt from '@angular/common/locales/it';
import {LOCALE_ID, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDateFormats,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {APP_MOMENT_DATE_FORMATS} from '../app/date.adapter';
import {MatPaginatorIntlIt} from './intl/mat-paginator-intl-it';
import {AgmCoreModule} from '@agm/core';

registerLocaleData(localeDeAt);

// If using Moment
const CUSTOM_DATE_TIME_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY HH:mm:ss',
    },
    display: {
        dateInput: 'DD/MM/YYYY HH:mm:ss',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCA6PjFKe5Lt8l3K2ZEZbqqy2Uo6C5hyXI',
            language: 'it',
            libraries: ['places', 'geometry']
        }),
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatIconModule,
        MatButtonModule,

        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,

        AgmCoreModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'it-IT' },
        { provide: MAT_DATE_LOCALE, useValue: 'it' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlIt },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS}
    ]
})
export class FuseSharedModule
{
}
